import React from "react";
import one from "../../assets/images/certificate/2.ISO 9001 Certificate.jpg";
import two from "../../assets/images/certificate/3.ISO 14001 Certificate.jpg";
import three from "../../assets/images/certificate/4.ISO 45001 Certificate.jpg";
import four from "../../assets/images/certificate/5.Oeko-Tex-Certificate(Zipper).jpg";
import five from "../../assets/images/certificate/6.Oeo-Tex-Certificate(Sewing Thread).jpg";
import six from "../../assets/images/certificate/7.BGAPMEA.jpg";
import { PhotoView } from "react-photo-view";
import ims from "../../assets/images/ims.png";
const Certificate = () => {
  return (
    <div className="container mx-auto">
      {/* certification */}
      <div className="pt-10 ">
        <h1 className="text-2xl font-bold pb-5 text-center uppercase">
          Certification
        </h1>
        <h3 className="text-2xl font-bold text-center uppercase">
          International Standard Management
        </h3>
        <p className="text-1xl pb-10 pt-10 text-left text-slate-400">
          SSB achieved Integrated Management System (IMS) certificate in
          ISO9001:2015, ISO14001:2015, ISO45001:2018. The three pillars of
          Sustainability play a crucial role in the prosperous growth of any{" "}
          <br></br>
          organization. In order to shape our Integrated Management System
          (IMS), we place utmost importance on our environmental and social
          policies. Our unwavering dedication to minimizing Environmental
          Impacts and maximizing Social Responsibility is deeply ingrained in
          our day-to-day operations, guaranteeing a reliable and dependable
          approach
        </p>

        <img src={ims} className="mt-5 pb-5 mx-auto" alt="" />

        {/* certificate */}
        <div>
          <h3 className="uppercase text-2xl font-bold ">ISO9001:2015</h3>
          <p className="text-1xl  pt-2 pb-5 text-slate-400">
            The international standard for management systems is based on
            "Quality Management Systems". QMS ensures quality processes,
            products, and services. Updated in 2015, the standard focuses on
            continuous improvement and stakeholder involvement. It helps
            organizations define roles, implement procedures, and plan actions.
            It assesses performance and suggests improvements at all levels.
          </p>
          <PhotoView src={one}>
            <img src={one} className="w-60 mx-auto" alt="" />
          </PhotoView>
        </div>
        {/* two */}
        <div>
          <h3 className=" text-2xl font-bold uppercase ">ISO14001:2015</h3>
          <p className="text-1xl  pt-2 pb-5 text-slate-400">
            The international standard "Environmental Management Systems" helps
            organizations develop an efficient EMS to identify and regulate
            environmental aspects related to their business. It ensures
            compliance with legal requirements and encourages continuous
            improvement in environmental performance
          </p>
          <PhotoView src={two}>
            <img src={two} className="w-60 mx-auto" alt="" />
          </PhotoView>
        </div>
        {/* three */}
        <div>
          <h3 className=" text-2xl font-bold ">ISO45001:2018</h3>
          <p className="text-1xl  pt-2 pb-5 text-slate-400">
            Occupational Health and Safety Assessment Series” is an
            international standard specifically intended for the management of
            health and safety on the workplace; it has been designed for
            organizations whose aim is to implement formal procedures to protect
            health and prevent threats to staff safety.
          </p>
          <PhotoView src={three}>
            <img src={three} className="w-60 mx-auto" alt="" />
          </PhotoView>
          <h3 className="text-2xl  font-bold text-center pt-2 uppercase">
            Product Quality Certificate{" "}
          </h3>
        </div>

        {/* four  */}
        <div>
          <h3 className="uppercase text-2xl font-bold ">
            Oeko-Tex Standard 100
          </h3>
          <p className="text-1xl  pt-2 pb-2 text-slate-400">
            We have obtained the Oeko-Tex® 100 certificate for our products
            Zipper and Sewing Thread, specifically for baby articles Product
            Class I, Annex 6.
          </p>
          <p className="text-1xl  pt-2 pb-2 text-slate-400">
            The Oeko-Tex® Standard 100 certifies textile products for compliance
            with laws and regulations on substances and chemicals. It's managed
            by the Oeko-Tex® Association, with 15 institutes in Europe and
            Japan, with representative agencies and offices in over 50 countries
            worldwide. Products undergo rigorous testing for heavy metals,
            pesticides, and formaldehyde. They're categorized into four classes
            based on use, with the most stringent requirements for baby articles
            under the Product Class I (baby articles) – Annex 6.
          </p>
          <p className="text-1xl  pt-2 pb-2 text-slate-400">
            By choosing Oeko-Tex® Standard 100 certified products, you can have
            confidence in the textiles you purchase, knowing that they have been
            thoroughly tested and meet the necessary standards for safety and
            environmental sustainability.
          </p>

          <div className="grid lg:grid-cols-2 grid-cols-2 mx-auto">
            <PhotoView src={four}>
              <img src={four} className="w-60 mx-auto" alt="" />
            </PhotoView>
            <PhotoView src={five}>
              <img src={five} className="w-60 mx-auto" alt="" />
            </PhotoView>
          </div>
        </div>
        {/* five */}
        <div>
          <h3 className="uppercase text-2xl font-bold pt-10 ">
            Membership Certificate
          </h3>
          <p className="text-1xl  pt-2 pb-5 text-slate-400">
            Bangladesh Garments Accessories & Packaging Manufacturers &
            Exporters Association (BGAPMEA) started its operation in the year
            1989. The Association is the prime trade organization to safeguard
            the interest of garments accessories & packaging manufacturers &
            exporters of the country and represents about 1900 Garments
            Accessories & Packaging industries of the country. Garments
            Accessories & Packaging (GAP) Industry is acting as a backward
            linkage industry of RMG sector.
          </p>
          <PhotoView src={six}>
            <img src={six} className="w-60 mx-auto" alt="" />
          </PhotoView>
        </div>
      </div>
      {/* certification end */}
    </div>
  );
};

export default Certificate;
