import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import qua from "../../assets/images/Quality/2. Lab-Color-Light-Box-Machine.jpg";
import qua2 from "../../assets/images/Quality/3.Zipper-Test-Operate.jpg";
import flow from "../../assets/images/Quality/4.Flow Control.png";
import "./QualityControl.css";
import qc from "../../assets/images/Quality/5.QC System (QC Flowchart Overview).jpg";
import test1 from "../../assets/images/Quality/Testing Machine 1.jpg";
import test2 from "../../assets/images/Quality/Testing Machine 2.jpg";
import test3 from "../../assets/images/Quality/Testing Machine 3.jpg";
import test4 from "../../assets/images/Quality/Testing Machine 4.jpg";
import testeq from "../../assets/images/Quality/Testing Equipment and Service Content.png";
import certi from "../../assets/images/Quality/8.Oeko-Tex-Certificate (All).jpg";
import useTitle from "../../hooks/useTitle";

const QualityControl = () => {
  useTitle("Quality Control");
  return (
    <div className="container mx-auto pt-40">
      {/* text part */}
      <div className="">
        <h2 className="text-3xl font-bold uppercase text-center">
          Quality Control
        </h2>
        <p className=" text-slate-400 mt-5">
          SSB insists on sustainable development, quality stability, and a
          world-class brand that meets global standards. The company also uses
          diverse management systems to meet customer requirements.
        </p>
        <p className=" text-slate-400 mt-5">
          Quality Policy: We are committed to continuously improving our
          products and services to ensure customer satisfaction. Through
          technological innovation, we strive for excellence and aim to
          establish ourselves as a leading brand.
        </p>
        <p className=" text-slate-400 mt-5">
          Quality Control: Our comprehensive quality control process includes
          Incoming, Process, and Finished Product Inspections to maintain the
          highest standards.
        </p>

        <h4 className="mt-5 text-2xl font-bold">Inspection Content: </h4>
        <p className="text-slate-400">
          <span className="text-sky-400 px-4">
            <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
          </span>
          Environmental Testing: Third Party Tests are conducted to assess the
          environmental impact of our products.
        </p>

        <p className="mt-5 text-slate-400">
          <span className="text-sky-400 px-4">
            <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
          </span>
          Comprehensive Testing: Our products undergo various tests, including
          Acid, Nickel Free, Needle, Color Fastness, Water Wash, Salt Spray,
          High and Low Temperature, and Standard and Certificate tests.
        </p>

        <p className="mt-5 pb-5 text-slate-400">
          <span className="text-sky-400 px-4">
            <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
          </span>
          Adoption of Standards: We adhere to internationally recognized
          standards such as ASTM D-2061, BS3084, DIN3419
        </p>

        <p className="pt-5 text-slate-400">
          We ensure unmatched quality and unwavering dedication to exceptional
          customer service by consistently investing in advanced manufacturing
          techniques and state-of-the-art facilities.
        </p>

        <div className="flex justify-center mt-10">
          <img src={qua} alt="" />
          <img src={qua2} className="w-52 ml-2" alt="" />
        </div>
      </div>

      {/* image part */}
      <div className="flex justify-center mt-20">
        <img src={flow} className="w-full flowimg" alt="" />
      </div>

      {/* qc system */}

      <div className="mt-20">
        <h2 className="text-3xl font-bold uppercase text-center">QC System</h2>
        <img src={qc} className="mt-20 mx-auto  qcimg" alt="" />
      </div>

      {/* testing */}
      <div className="mt-20 ">
        <h3 className="text-3xl uppercase font-bold text-center">
          Testing Equipment
        </h3>
        <h4 className="text-2xl text-center pt-5">Quality Main Test Items</h4>
      </div>

      <div className="flex justify-center mt-10">
        <div className="flex flex-col items-center">
          <img src={test1} className="w-52" alt="" />
        </div>
        <div className="flex flex-col items-center">
          <img src={test2} className="w-52" alt="" />
        </div>
        <div className="flex flex-col items-center">
          <img src={test3} className="w-52" alt="" />
        </div>
        <div className="flex flex-col items-center">
          <img src={test4} className="w-52" alt="" />
        </div>
      </div>

      <div className="mt-20">
        <h2 className="text-3xl font-bold uppercase text-center">
          Testing Equipment and Service Content
        </h2>
        <img src={testeq} className="mt-20 mx-auto  qcimg" alt="" />
      </div>

      {/* test end */}

      <div>
        <h3 className="text-3xl  font-bold  mt-20">Confidence In Textiles</h3>
        <p className=" mt-5 text-1xl pt-2 text-slate-400">
          The Oeko-Tex® Standard 100 certifies textile products for compliance
          with laws and regulations on substances and chemicals. It's managed by
          the Oeko-Tex® Association, with 15 institutes in Europe and Japan,
          with representative agencies and offices in over 50 countries
          worldwide. Products undergo rigorous testing for heavy metals,
          pesticides, and formaldehyde. They're categorized into four classes
          based on use, with the most stringent requirements for baby articles
          under the Product Class I (baby articles) – Annex 6.
        </p>
        <p className="text-1xl pt-2 text-slate-400">
          By choosing Oeko-Tex® Standard-100 certified products, you can have
          confidence in the textiles you purchase, knowing that they have been
          thoroughly tested and meet the necessary standards for safety and
          environmental sustainability.
        </p>

        <div>
          <img src={certi} className="mt-20+ mx-auto  w-full" alt="" />
        </div>
      </div>
    </div>
  );
};

export default QualityControl;
