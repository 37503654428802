import React from "react";
import Footer from "../../Shared/Footer/Footer";
import Header from "../../Shared/Header/Header";

import Background from "../../assets/images/floating_elements.png";
import errorpageimg from "../../assets/images/errorpage.png";
import { Link } from "react-router-dom";
import useTitle from "../../hooks/useTitle";
const Error404 = () => {
  useTitle("Error 404");
  return (
    <div className="" style={{ background: `url(${Background})` }}>
      <Header></Header>
      <div className="pt-15 grid grid-cols-1 container mx-auto">
        <div className="hero min-h-screen ">
          <div className="hero-content flex-col lg:flex-row">
            <img src={errorpageimg} alt="Error404" />
            <div>
              <h1 className="text-8xl font-bold text-blue-600 ">404</h1>
              <p className="py-6">
                The 404 error message is a common occurrence on the internet,
                and it is something that every web user has encountered at least
                once. This error message appears when a user tries to access a
                webpage that doesn't exist or has been removed. The message is
                usually displayed as "404 Not Found" or "Error 404".
              </p>
              <Link
                className="btn bg-blue-600 rounded-none border-blue-600 border-2 hover:bg-white hover:text-blue-600 "
                to="/"
              >
                Return Back
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Error404;
