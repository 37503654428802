import React from "react";
import thread from "../../assets/images/updated image/9.FRONT PAGE PICTURE S-TREAD - 3.jpg";
import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./thread.css";
const Thread = () => {
  return (
    <div className="pt-32 container mx-auto">
      <div>
        <img
          src={thread}
          className="max-w-4xl mx-auto rounded-xl thgb"
          alt=""
        />
      </div>
      <h3 className="text-4xl  pt-10 font-bold text-center uppercase">
        SEWING THREAD
      </h3>

      <p className="text-1xl pt-10 text-slate-400">
        Since 2006, we have been providing high-quality sewing threads to meet
        all your garment needs. Our factory is equipped with cutting-edge
        technology and a modern laboratory, and our team of seasoned
        professionals ensures the highest standard of quality.
      </p>

      <h3 className="text-3xl font-bold  uppercase pt-5">
        100% Spun Polyester Sewing Thread
      </h3>
      <p className="text-1xl  pt-5 text-slate-400">
        Our thread is made from top-grade staple polyester fibers and silicone
        oil, which guarantees exceptional seam performance and lubricity. Our
        thread offers a range of benefits, such as high strength, excellent
        elasticity, appropriate shrinkage, superior friction, remarkable washing
        fastness, and commendable chemical stability, making it the ideal choice
        for a variety of sewing applications.
      </p>
      <p className="text-1xl pt-5 text-slate-400">
        We offer a wide range of thread counts, including 60/2, 60/3, 50/2,
        40/2, 40/3, 20/2, 20/3, 20/4, 20/9 and 12/3 etc.
      </p>

      <h4 className="font-bold pt-10 text-2xl uppercase">
        Raw Material Sourcing :{" "}
      </h4>
      <p className="text-1xl pt-2 text-slate-400">
        We source all raw materials, such as yarn, chemicals, and dyes,
        exclusively from Oeko-tex® approved suppliers. This ensures our quality
        standards align with international norms and we prioritize trusted
        sourcing.
      </p>

      <h4 className="font-bold pt-10 text-2xl uppercase">End Uses: </h4>
      <p className="text-1xl pt-2 text-slate-400">
        Different counts of sewing thread are suitable for all kinds of
        high-grade textile clothing, shoes, home textiles and decorative items.
      </p>

      <h4 className="font-bold pt-10 text-2xl uppercase">Quality Standard:</h4>
      <p className="text-1xl pt-2 text-slate-400">
        All types of thread manufactured in its facility will confirm to
        Oeko-Tex Standard 100. Any colour, including fluorescent; grey mélange
        etc can be supplied. Bleach proof, AZO &color fastness maintained
        internal stands manufactured in its current facility on demand of
        customer.
      </p>

      <h3 className="text-3xl  text-left pt-10  font-bold uppercase">
        Features
      </h3>
      <p className="pt-5 pb-2 text-slate-400">
        <span className="text-sky-400 px-4">
          <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
        </span>
        High tenacity
      </p>

      <p className="pt-2 pb-2 text-slate-400">
        <span className="text-sky-400 px-4">
          <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
        </span>
        Natural aspects
      </p>

      <p className="pt-2 pb-2 text-slate-400">
        <span className="text-sky-400 px-4">
          <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
        </span>
        Low shrinkage
      </p>

      <p className="pt-2 pb-2 text-slate-400">
        <span className="text-sky-400 px-4">
          <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
        </span>
        Micro molecular lubrication for very smooth high-speed sewing operation
      </p>

      <p className="pt-2 pb-2 text-slate-400">
        <span className="text-sky-400 px-4">
          <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
        </span>
        High resistance to ultraviolet radiation
      </p>

      <p className="pt-2 pb-2 text-slate-400">
        <span className="text-sky-400 px-4">
          <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
        </span>
        Knotless
      </p>

      {/* text */}

      <h3 className="text-3xl font-bold pt-10 uppercase">
        100% Polyester Filament Sewing Thread
      </h3>
      <p className="text-1xl  pt-5 text-slate-400">
        Polyester Filament thread is one of the most usable commercial thread
        around the world. It is the finest and synthetic yarn that are used is
        for different purposes like Embroidery, sewing, knitting, weaving and
        more.
      </p>

      <p className="pt-5">
        <span className="text-sky-400">Count: </span> 150D (Sizing & Non-Sizing)
      </p>
      {/* feature */}
      <h3 className="text-3xl  text-left pt-10  font-bold uppercase">
        Features
      </h3>
      <p className="pt-5 pb-2 text-slate-400">
        <span className="text-sky-400 px-4">
          <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
        </span>
        Sewing of lightweight fabrics
      </p>

      <p className="pt-2 pb-2 text-slate-400">
        <span className="text-sky-400 px-4">
          <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
        </span>
        High-speed multi-directional sewing
      </p>

      <p className="pt-2 pb-2 text-slate-400">
        <span className="text-sky-400 px-4">
          <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
        </span>
        High abrasion resistance
      </p>

      <p className="pt-2 pb-2 text-slate-400">
        <span className="text-sky-400 px-4">
          <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
        </span>
        Allows the use of smaller diameter thread to minimize Seam Puckering or
        Needle cutting
      </p>

      <p className="pt-2 pb-2 text-slate-400">
        <span className="text-sky-400 px-4">
          <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
        </span>
        Usually made of long filament fibers
      </p>

      <p className="pt-2 pb-2 text-slate-400">
        <span className="text-sky-400 px-4">
          <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
        </span>
        Continuous and long
      </p>

      <p className="pt-2 pb-2 text-slate-400">
        <span className="text-sky-400 px-4">
          <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
        </span>
        Lustrous
      </p>

      <p className="pt-2 pb-2 text-slate-400">
        <span className="text-sky-400 px-4">
          <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
        </span>
        Easy manufacturing
      </p>

      <p className="pt-2 pb-2 text-slate-400">
        <span className="text-sky-400 px-4">
          <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
        </span>
        Smooth
      </p>

      <p className="pt-2 pb-2 text-slate-400">
        <span className="text-sky-400 px-4">
          <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
        </span>
        Slick
      </p>
      {/* feature end */}

      <h3 className="text-2xl font-bold pt-5 uppercase">Applications</h3>
      <p className="pt-2 text-slate-400">
        Underwear, Knitwear, baby wear, Sleepwear and leather products.{" "}
      </p>
      <h4 className="text-2xl uppercase text-center pt-5">Quality Products</h4>
      <p className="pt-5 text-center text-slate-400">
        Products are tested thoroughly for strength, color perfection & sew
        ability in order to ensure higher quality
      </p>

      <h4 className="text-2xl uppercase text-center pt-5">Shorter Lead Time</h4>
      <p className="pt-5 text-center text-slate-400">
        We pride in maintaining the shortest lead time possible in the market in
        order to provide our customers with the best service possible.
      </p>
    </div>
  );
};

export default Thread;
