import React from "react";
import { Link } from "react-router-dom";
// import { TypeAnimation } from "react-type-animation";
import "./Banner.css";
import BannerSlider from "./BannerSlider/BannerSlider";
const Banner = () => {
  return (
    <div className="mx-auto container    ">
      <div className="grid lg:grid-cols-2  grid-cols-1 lg:pt-0  pt-20">
        {/* slider */}
        <div>
          <div className="card  text-left  ">
            <div className="card-body">
              <h2 className="card-title lg:text-5xl text-4xl font-bold text-black pb-10">
                Quality You Can Rely On
              </h2>
              {/* <h3 className=" lg:text-5xl text-3xl font-extrabold pt-5 pb-10  text-transparent  bg-clip-text bg-gradient-to-r from-blue-400 to-blue-800">
                <TypeAnimation
                  sequence={[
                    "Nylon Zipper ",
                    1000,
                    "Metal Zipper ",
                    1000,
                    // 'Clipping Path',
                    // 1000,
                    "Slider Zipper",
                    1000,
                    "Sewing Thread ",
                    1000,

                    () => {
                      // console.log('Done typing!'); // Place optional callbacks anywhere in the array
                    },
                  ]}
                  wrapper="h2"
                  cursor={true}
                  repeat={Infinity}
                  style={{ fontSize: "5xl" }}
                ></TypeAnimation>
              </h3> */}
              <p>
                SSB" Brand Zipper, leveraging advanced facilities and equipment.
                Our premium Zipper & Thread products are crafted with
                non-hazardous materials and cutting-edge machinery, ensuring
                superior quality and environmental sustainability
              </p>
              <div className="card-actions pt-10">
                <Link
                  to="/pricing"
                  className="btn rounded-none  bg-blue-600  border-blue-600 hover:border-2 hover:bg-white  hover:text-blue-600  hover:border-blue-600 shadow-lg"
                >
                  TRY NOW
                </Link>

                <Link
                  to="/contactus"
                  className="btn rounded-none  bg-white text-blue-600 border-blue-600 border-2 hover:bg-blue-600  hover:text-white hover:border-none"
                >
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div>
          <BannerSlider></BannerSlider>
        </div>
      </div>
    </div>
  );
};

export default Banner;
