import React from "react";
import history from "../../assets/images/SSB-History-New.jpg";
import ims from "../../assets/images/ims.png";
import one from "../../assets/images/certificate/2.ISO 9001 Certificate.jpg";
import two from "../../assets/images/certificate/3.ISO 14001 Certificate.jpg";
import three from "../../assets/images/certificate/4.ISO 45001 Certificate.jpg";
import four from "../../assets/images/certificate/5.Oeko-Tex-Certificate(Zipper).jpg";
import five from "../../assets/images/certificate/6.Oeo-Tex-Certificate(Sewing Thread).jpg";
import six from "../../assets/images/certificate/7.BGAPMEA.jpg";

import { PhotoView } from "react-photo-view";

const AboutUs = () => {
  return (
    <div className="container mx-auto pt-40">
      <div className="grid grid-cols-1">
        {/* history */}
        <div>
          <h1 className="text-2xl font-bold">History</h1>
          <p className="text-1xl pb-10">
            Established in 2006, Fulpukuria Thread Accessories Limited is a
            Sewing Thread manufacturing Company located in Dhaka, Bangladesh. As
            a company focused on exporting our products, we are fully committed
            to meeting the needs and requirements of our esteemed customers. In
            order to cater to their demands, we introduced the high-performance
            "SSB" brand Zipper in 2017, utilizing state-of-the-art facilities
            and equipment.
          </p>

          <p className="text-1xl pb-10">
            Our extensive product range includes Zipper & Thread, which is
            manufactured using premium quality nonhazardous raw materials and
            advanced machinery. We consistently upgrade our machinery to ensure
            that we possess cutting-edge production capabilities. Our primary
            objective is to provide prompt and efficient service at competitive
            prices, while also prioritizing environmental sustainability and the
            timely fulfillment of large orders
          </p>
          <p className="text-1xl pb-10">
            To maintain the highest standards, we have implemented individual
            Integrated Management System (IMS) that specifically focus on
            Quality, Safety, and Environment. These systems have been designed
            in accordance with internationally recognized standards such as
            ISO9001:2015 (Quality Management), ISO14001:2015 (Environmental
            Management System), and ISO45001:2015 (Occupational Health and
            Safety Management System).
          </p>

          <p className="text-1xl pb-10">
            At SSB Thread Accessories Limited, we place great emphasis on
            quality control. Our in-house testing lab, overseen by experienced
            quality controllers, ensures that our raw materials, finished
            products, and nonfinished products meet stringent international
            inspection standards such as Oeko-tex Standard-100, British BS3084,
            American ASTMD2061, and German DIN3419.
          </p>

          <p className="text-1xl pb-10">
            . Customer satisfaction is of utmost importance to us, and we are
            fully dedicated to forging a prosperous future together with our
            valued customers.
          </p>
        </div>
        {/* history end */}

        <div>
          <h2 className="text-3xl font-bold  text-center">
            <span className="text-blue-500 ">SSB </span> History
            <img src={history} className="mt-20" alt="" />
          </h2>
        </div>

        {/* image end */}

        {/* mission */}
        <div className="mt-20 text-center">
          <h1 className="text-2xl font-bold text-blue-500">Mission</h1>
          <p className="text-1xl pb-10">
            Our objective is to produce a product that is environmentally and
            socially conscious, <br></br> ensuring the highest quality and
            utmost customer satisfaction through prompt delivery.
          </p>
        </div>

        {/* mission end */}
        {/* vision */}
        <div className="mt-10 text-center">
          <h1 className="text-2xl font-bold text-blue-500">Vision</h1>
          <p className="text-1xl pb-10">
            We aim to expand our business globally, with the support of our
            dedicated employee, and become the top <br></br>
            choice for customers seeking high-quality and sustainable services
            and products. Our ultimate aspiration is to <br></br> establish
            ourselves as the most reliable and preferred zipper brand in
            Bangladesh and worldwide.
          </p>
        </div>
        {/* vision end */}
        {/* certification */}
        <div className="mt-10 ">
          <h1 className="text-2xl font-bold pb-5 text-center">Certification</h1>
          <h3 className="text-blue-500 text-2xl font-bold text-center">
            International Standard Management
          </h3>
          <p className="text-1xl pb-10 pt-10 text-left">
            SSB achieved Integrated Management System (IMS) certificate in
            ISO9001:2015, ISO14001:2015, ISO45001:2018. The three pillars of
            Sustainability play a crucial role in the prosperous growth of any{" "}
            <br></br>
            organization. In order to shape our Integrated Management System
            (IMS), we place utmost importance on our environmental and social
            policies. Our unwavering dedication to minimizing Environmental
            Impacts and maximizing Social Responsibility is deeply ingrained in
            our day-to-day operations, guaranteeing a reliable and dependable
            approach
          </p>

          <img src={ims} className="mt-5 pb-5 mx-auto" alt="" />

          {/* certificate */}
          <div>
            <h3 className="text-blue-500 text-2xl font-bold ">ISO9001:2015</h3>
            <p className="text-1xl  pt-2 pb-5">
              The international standard for management systems is based on
              "Quality Management Systems". QMS ensures quality processes,
              products, and services. Updated in 2015, the standard focuses on
              continuous improvement and stakeholder involvement. It helps
              organizations define roles, implement procedures, and plan
              actions. It assesses performance and suggests improvements at all
              levels.
            </p>
            <PhotoView src={one}>
              <img src={one} className="w-60 mx-auto" alt="" />
            </PhotoView>
          </div>
          {/* two */}
          <div>
            <h3 className="text-blue-500 text-2xl font-bold ">ISO14001:2015</h3>
            <p className="text-1xl  pt-2 pb-5">
              The international standard "Environmental Management Systems"
              helps organizations develop an efficient EMS to identify and
              regulate environmental aspects related to their business. It
              ensures compliance with legal requirements and encourages
              continuous improvement in environmental performance
            </p>
            <PhotoView src={two}>
              <img src={two} className="w-60 mx-auto" alt="" />
            </PhotoView>
          </div>
          {/* three */}
          <div>
            <h3 className="text-blue-500 text-2xl font-bold ">ISO45001:2018</h3>
            <p className="text-1xl  pt-2 pb-5">
              Occupational Health and Safety Assessment Series” is an
              international standard specifically intended for the management of
              health and safety on the workplace; it has been designed for
              organizations whose aim is to implement formal procedures to
              protect health and prevent threats to staff safety.
            </p>
            <PhotoView src={three}>
              <img src={three} className="w-60 mx-auto" alt="" />
            </PhotoView>
            <h3 className="text-2xl  font-bold text-center pt-2">
              Product Quality Certificate{" "}
            </h3>
          </div>

          {/* four  */}
          <div>
            <h3 className="text-blue-500 text-2xl font-bold ">
              Oeko-Tex Standard 100
            </h3>
            <p className="text-1xl  pt-2 pb-2">
              We have obtained the Oeko-Tex® 100 certificate for our products
              Zipper and Sewing Thread, specifically for baby articles Product
              Class I, Annex 6.
            </p>
            <p className="text-1xl  pt-2 pb-2">
              The Oeko-Tex® Standard 100 certifies textile products for
              compliance with laws and regulations on substances and chemicals.
              It's managed by the Oeko-Tex® Association, with 15 institutes in
              Europe and Japan, with representative agencies and offices in over
              50 countries worldwide. Products undergo rigorous testing for
              heavy metals, pesticides, and formaldehyde. They're categorized
              into four classes based on use, with the most stringent
              requirements for baby articles under the Product Class I (baby
              articles) – Annex 6.
            </p>
            <p className="text-1xl  pt-2 pb-2">
              By choosing Oeko-Tex® Standard 100 certified products, you can
              have confidence in the textiles you purchase, knowing that they
              have been thoroughly tested and meet the necessary standards for
              safety and environmental sustainability.
            </p>

            <div className="grid lg:grid-cols-2 grid-cols-2 mx-auto">
              <PhotoView src={four}>
                <img src={four} className="w-60 mx-auto" alt="" />
              </PhotoView>
              <PhotoView src={five}>
                <img src={five} className="w-60 mx-auto" alt="" />
              </PhotoView>
            </div>
          </div>
          {/* five */}
          <div>
            <h3 className="text-blue-500 text-2xl font-bold pt-10 ">
              Membership Certificate
            </h3>
            <p className="text-1xl  pt-2 pb-5">
              Bangladesh Garments Accessories & Packaging Manufacturers &
              Exporters Association (BGAPMEA) started its operation in the year
              1989. The Association is the prime trade organization to safeguard
              the interest of garments accessories & packaging manufacturers &
              exporters of the country and represents about 1900 Garments
              Accessories & Packaging industries of the country. Garments
              Accessories & Packaging (GAP) Industry is acting as a backward
              linkage industry of RMG sector.
            </p>
            <PhotoView src={six}>
              <img src={six} className="w-60 mx-auto" alt="" />
            </PhotoView>
          </div>
        </div>
        {/* certification end */}
        {/* user manual */}
        <div className="mt-10 ">
          <h1 className="text-2xl font-bold text-blue-500 text-center">
            User Manual
          </h1>
          <p className="text-1xl pb-2">
            <span>{/* <FontAwesomeIcon icon="fa-regular fa-circle" /> */}</span>
            Due to the variations in strength values, chemical reactions, and
            appearance of zippers based on their types, it is important to
            provide the authorized personnel of the zipper company with
            information about the intended usage location before placing an
            order.
          </p>
          <p className="text-1xl pb-2">
            The zipper on the product should be washed after it is fully closed.
          </p>
          <p className="text-1xl pb-2">
            Washed zipper products should be dried quickly and should not be
            left wet. If the zipper on a wet product has been dried, it should
            be opened and closed.
          </p>
          <p className="text-1xl pb-2">
            During the washing of zipper products, auxiliary chemical substances
            should be used in appropriate proportions, and the processing time
            should not exceed 30 minutes.
          </p>
          <p className="text-1xl pb-2">
            It is essential to remember to wash products with zippers turned
            inside out as much as possible
          </p>
          <p className="text-1xl pb-2">
            In pumice stone washes, the final rinse of the zipper product should
            be done thoroughly, and it should be dried immediately.
          </p>

          <p className="text-1xl pb-2">
            It should not be overlooked that storing zippers and zipper products
            in unsuitable environments can cause issues with tapes and teeth.
            They should not be subjected to excessive humidity and high
            temperatures, and should not be placed under heavy weights that
            could compromise their appearance and damage the metal components
          </p>

          <p className="text-1xl pb-2">
            After the zipper is sewn, if the product is steam-pressed or treated
            with chemicals, it should be cooled down and placed in polyethylene
            bags without delay. Prolonged storage in these bags without cooling
            down can lead to chemical reactions.
          </p>

          <p className="text-1xl pb-2">
            The pressure and temperature applied while pressing or ironing the
            zipper product should be adjusted carefully. Improper ironing can
            deform the zipper structure.
          </p>

          <p className="text-1xl pb-2">
            It should be noted that dark-colored zippers sewn onto light-colored
            garments may cause color transfer issues.
          </p>

          <p className="text-1xl pb-2">
            No processes other than washing should be applied to zippers with
            coatings (oxide, tin, antique brass, etc.). It should be kept in
            mind that non-standard industrial processes can cause the coating to
            color the fabrics and its own tape
          </p>

          <p className="text-1xl pb-2">
            Each zipper should be sewn using a specific zipper sewing foot
            corresponding to its type.
          </p>

          <p className="text-1xl pb-2">
            During zipper sewing, care should be taken to prevent the entry of
            foreign objects such as threads, lint, and fabric between the zipper
            teeth. Especially in the production of bags, shoes, and boots, the
            use of adhesive materials or solutions during sewing can render the
            zipper teeth and slider unable to perform their function
          </p>

          <p className="text-1xl pb-2">
            Avoid striking the nylon zipper teeth, especially during their
            sewing.
          </p>

          <p className="text-1xl pb-2">
            In baby and children's products, where needle breakage is checked,
            sliders of zippers should be manufactured using the non-magnetic
            method to pass through detectors without triggering an alarm.
          </p>

          <p className="text-1xl pb-2">
            During zipper sewing, the path for the slider's smooth movement
            should be left open.
          </p>

          <p className="text-1xl pb-2">
            Longitudinal cutting should not be made on the strips before sewing
            zippers. If done so, the warp threads of the strip will disperse,
            rendering the zipper unusable.
          </p>

          <p className="text-1xl pb-2">
            To maximize corrosion resistance, it is recommended to prefer
            lacquered components.
          </p>

          <p className="text-1xl pb-2">
            In separable zipper types, two side strips of different zippers
            should not be paired together, even if they have the same color and
            length. This can result in cosmetic flaws such as buckling.
          </p>

          <p className="text-1xl pb-2">
            If spray-painted handles are used as accessories on zippers, there
            may be deformation on the swinging handle due to movement and impact
            during washing. To prevent this, the handles should be fixed or worn
            after washing.
          </p>

          <p className="text-1xl pb-2">
            Due to the structure of zippers, which consist of metal,
            monofilament, plastic, accessories, and coatings, it is recommended
            by SSB ZIPPER to adhere to the rules of washing, protection, and
            appropriate usage area for their healthy functioning and long-term
            use to the maximum extent possible.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
