import React from "react";
import Constraction from "../../component/Constraction/Constraction";

const Blog = () => {
  return (
    <div>
      <Constraction></Constraction>
    </div>
  );
};

export default Blog;
