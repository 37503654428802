import React from "react";
import one from "../../assets/images/certificate/2.ISO 9001 Certificate.jpg";
import two from "../../assets/images/certificate/3.ISO 14001 Certificate.jpg";
import three from "../../assets/images/certificate/4.ISO 45001 Certificate.jpg";
import four from "../../assets/images/certificate/5.Oeko-Tex-Certificate(Zipper).jpg";
import five from "../../assets/images/certificate/6.Oeo-Tex-Certificate(Sewing Thread).jpg";
import six from "../../assets/images/certificate/7.BGAPMEA.jpg";
import { PhotoView } from "react-photo-view";

const Certification = () => {
  return (
    <div className="container mx-auto">
      <h2 className="text-4xl font-bold uppercase pb-10 pt-10">
        Certification
      </h2>
      <div className="grid lg:grid-cols-6 grid-cols-3">
        <div>
          <PhotoView src={one}>
            <img className="w-52 rounded-sm" src={one} alt="" />
          </PhotoView>
        </div>
        <div>
          <PhotoView src={two}>
            <img className="w-52 rounded-sm" src={two} alt="" />
          </PhotoView>
        </div>
        <div>
          <PhotoView src={three}>
            <img className="w-52 rounded-sm" src={three} alt="" />
          </PhotoView>
        </div>
        <div>
          <PhotoView src={four}>
            <img className="w-52 rounded-sm" src={four} alt="" />
          </PhotoView>
        </div>
        <div>
          <PhotoView src={five}>
            <img className="w-52 rounded-sm" src={five} alt="" />
          </PhotoView>
        </div>
        <div>
          <PhotoView src={six}>
            <img className="w-52 rounded-sm" src={six} alt="" />
          </PhotoView>
        </div>
      </div>
    </div>
  );
};

export default Certification;
