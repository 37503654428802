import React from "react";

const Missionandvission = () => {
  return (
    <div className="pt-20 container mx-auto">
      {/* mission */}
      <div className="mt-20 text-center">
        <h1 className="text-2xl font-bold text-black uppercase">Mission</h1>
        <p className="text-1xl pb-10 text-slate-400">
          Our objective is to produce a product that is environmentally and
          socially conscious, <br></br> ensuring the highest quality and utmost
          customer satisfaction through prompt delivery.
        </p>
      </div>

      {/* mission end */}
      {/* vision */}
      <div className="mt-10 pb-44 text-center">
        <h1 className="text-2xl font-bold text-black uppercase">Vision</h1>
        <p className="text-1xl pb-80 text-slate-400">
          We aim to expand our business globally, with the support of our
          dedicated employee, and become the top <br></br>
          choice for customers seeking high-quality and sustainable services and
          products. Our ultimate aspiration is to <br></br> establish ourselves
          as the most reliable and preferred zipper brand in Bangladesh and
          worldwide.
        </p>
      </div>
      {/* vision end */}
    </div>
  );
};

export default Missionandvission;
