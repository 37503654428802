import React from "react";
import history from "../../assets/images/SSB-History-New.jpg";
const History = () => {
  return (
    <div className="pt-40 container mx-auto">
      <p className="text-1xl pb-10 text-slate-400">
        Established in 2006, Fulpukuria Thread Accessories Limited is a Sewing
        Thread manufacturing Company located in Dhaka, Bangladesh. As a company
        focused on exporting our products, we are fully committed to meeting the
        needs and requirements of our esteemed customers. In order to cater to
        their demands, we introduced the high-performance "SSB" brand Zipper in
        2017, utilizing state-of-the-art facilities and equipment.
      </p>

      <p className="text-1xl pb-10  text-slate-400">
        Our extensive product range includes Zipper & Thread, which is
        manufactured using premium quality nonhazardous raw materials and
        advanced machinery. We consistently upgrade our machinery to ensure that
        we possess cutting-edge production capabilities. Our primary objective
        is to provide prompt and efficient service at competitive prices, while
        also prioritizing environmental sustainability and the timely
        fulfillment of large orders
      </p>
      <p className="text-1xl pb-10  text-slate-400">
        To maintain the highest standards, we have implemented individual
        Integrated Management System (IMS) that specifically focus on Quality,
        Safety, and Environment. These systems have been designed in accordance
        with internationally recognized standards such as ISO9001:2015 (Quality
        Management), ISO14001:2015 (Environmental Management System), and
        ISO45001:2015 (Occupational Health and Safety Management System).
      </p>

      <p className="text-1xl pb-10  text-slate-400">
        At SSB Thread Accessories Limited, we place great emphasis on quality
        control. Our in-house testing lab, overseen by experienced quality
        controllers, ensures that our raw materials, finished products, and non
        finished products meet stringent international inspection standards such
        as Oeko-tex Standard-100, British BS3084, American ASTMD2061, and German
        DIN3419.
      </p>

      <p className="text-1xl pb-10  text-slate-400">
        . Customer satisfaction is of utmost importance to us, and we are fully
        dedicated to forging a prosperous future together with our valued
        customers.
      </p>

      <div>
        <h2 className="text-3xl font-bold  text-center">
          <span className="text-sky-400 ">SSB </span> History
          <img src={history} className="mt-20 w-1/2  mx-auto " alt="" />
        </h2>
      </div>
    </div>
  );
};

export default History;
