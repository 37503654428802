import React from "react";
import useTitle from "../../hooks/useTitle";
import nylon from "../../assets/images/updated image/1. Nylon Zipper.jpg";
import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import nylonone from "../../assets/images/Nylon Zipper/3 Nylon Invisible Close End.jpg";
import var1 from "../../assets/images/Nylon Zipper/ZYLON ZIPPER FORMS & VARIATIONS new.png";
import Nylon3CloseEndMS from "../../assets/images/Nylon Zipper/1.3 Nylon Close End (MS) big pic.jpg";
import Nylon3OpenEndPS from "../../assets/images/Nylon Zipper/3 Nylon Open End (PS).jpg";
import Nylon5CloseEndMS from "../../assets/images/Nylon Zipper/5 Nylon Close End (MS) big pic.jpg";
import Nylon5OpenEndms from "../../assets/images/Nylon Zipper/5 Nylon Open End 2way (MS) big pic.jpg";
import Nylon5CloseEndps from "../../assets/images/Nylon Zipper/5 Nylon Close End (PS).jpg";
import Nylon5OpenEndPs from "../../assets/images/Nylon Zipper/5 Nylon Open End (PS).jpg";
import Nylon5CloseEndps1 from "../../assets/images/Nylon Zipper/5 Nylon Close End (PS) big pic.jpg";
import Nylon5OpenEnd2Way from "../../assets/images/Nylon Zipper/5 Nylon Open End 2way (MS).jpg";
import NylonWaterproof3CloseEnd from "../../assets/images/Nylon Zipper/3 Nylon waterproof Close End (MS).jpg";
import nylonSilverTeethClose from "../../assets/images/Nylon Zipper/3 Nylon Silver Tooth Close End (PS) big pic.jpg";
import NylonSilverTeeth5OpenEnd from "../../assets/images/Nylon Zipper/3 Nylon Silver Tooth Open End (PS).jpg";
import nylonWaterproof5OpenEnd from "../../assets/images/Nylon Zipper/5 Nylon waterproof Open End (MS).jpg";
import Nylon5CloseEnd from "../../assets/images/Nylon Zipper/newone.jpg";
import Nylon5XForm from "../../assets/images/Nylon Zipper/nylon 5x form.jpg";

const NylonZipper = () => {
  useTitle("Nylon Zipper");
  return (
    <div className="pt-20 container mx-auto">
      <div>
        <img src={nylon} className="mx-auto mt-10 rounded-xl w-auto" alt="" />
      </div>

      <h3 className="text-black text-4xl font-bold text-center pt-10 uppercase">
        Nylon ( CF ) Zippers
      </h3>

      <p className="text-1xl pt-10 ">
        Coil zippers can be developed in a wide range of sizes and styles. Coil
        zippers are made from a continuous coil of monofilament in place of
        individual teeth. The teeth of these zippers are made of a coil of
        extruded nylon wire sewn onto the dyedperfectly match the color of the
        fabric on clothing with polyester tape. The strength comes from the
        tightly placed elements of continuous coil plus its unique ability to
        “self-heal” if accidentally opened behind the slider.
      </p>

      <h3 className="text-3xl  text-left pt-10  font-bold">Features</h3>
      <p className="pt-5 pb-2">
        <span className="text-sky-400 px-4">
          <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
        </span>
        Imported fadeless straight tape
      </p>
      <p className="pt-2 pb-2">
        <span className="text-sky-400 px-4">
          <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
        </span>
        Available in 2 versions zipperteeth –Visible (Non Reversed) and Reversed
        Teeth
      </p>
      <p className="pt-2 pb-2">
        <span className="text-sky-400 px-4">
          <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
        </span>
        Available visible chain, Enamel Painted & Plating color Slider
      </p>
      <p className="pt-2 pb-2">
        <span className="text-sky-400 px-4">
          <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
        </span>
        Toughness, Handiness, Environmental friendliness and a lower shrinkage
        rate, non-magnetic.
      </p>
      <p className="pt-2 pb-2">
        <span className="text-sky-400 px-4">
          <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
        </span>
        Durable, dead soft, good ductility, High tensile strength, bright
        surface.
      </p>
      <p className="pt-2 pb-2">
        <span className="text-sky-400 px-4">
          <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
        </span>
        Lower shrinkage rate
      </p>
      <p className="pt-2 pb-2">
        <span className="text-sky-400 px-4">
          <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
        </span>
        AZO, Nickel, Lead, Phthalate, Magnet, Ferrous Free
      </p>

      {/* texxt */}
      <p className="text-1xl text-center pt-1 text-black">
        Technical characteristics of the Nylon Coil product
        <br></br> Nylon zipper is made of spiral polyester fiber. It is soft,
        smooth surface and multi-colored, which is the most common type of
        zipper.<br></br> We have a variety of types Nylon zipper such as{" "}
        <span className="font-bold">
          Regular, Reversed/converted. Water-repellent/ Waterproof.
        </span>
      </p>

      <p className="text-center pt-5">
        <span className="font-bold">Technology </span>
        <br></br> Polyester yarn.<br></br> Available sizes: <br></br> 3mm | 5mm{" "}
        <br></br> Use for: <br></br>Trousers | Jackets | Dresses | Sportswear |
        |Tents | Household linen | Bags& many more
      </p>

      {/* image part start */}
      <div className="grid grid-cols-1">
        <div className="flex mx-auto">
          <div className="text-center shadow-lg shadow-black mx-5 mt-5">
            <img src={Nylon3CloseEndMS} className="w-60" alt="" />
            <h3>Nylon #3 Close End (M/S) </h3>
          </div>

          <div className="text-center shadow-lg shadow-black mx-5 mt-5">
            <img src={Nylon3OpenEndPS} className="w-60" alt="" />
            <h3 className="pt-10">Nylon # 3 Open End (P/S)</h3>
          </div>
        </div>

        {/* two */}
        <div className="flex mx-auto">
          <div className="text-center shadow-lg shadow-black mx-5 mt-5">
            <img src={Nylon5CloseEndMS} className="w-60" alt="" />
            <h3>Nylon #5 Close End (M/S) </h3>
          </div>

          <div className="text-center shadow-lg shadow-black mx-5 mt-5">
            <img src={Nylon5OpenEndms} className="w-60" alt="" />
            <h3>Nylon # 5 Open End (M/S) </h3>
          </div>
        </div>
        {/* three */}
        <div className="flex mx-auto">
          <div className="text-center shadow-lg shadow-black mx-5 mt-5">
            <img src={Nylon5CloseEndps} className="w-60" alt="" />
            <h3>Nylon #5 Close End (P/S) </h3>
          </div>

          <div className="text-center shadow-lg shadow-black mx-5 mt-5">
            <img src={Nylon5OpenEndPs} className="w-60" alt="" />
            <h3>Nylon # 5 Open End (P/S)</h3>
          </div>
        </div>
        {/* four */}
        <div className="flex mx-auto">
          <div className="text-center shadow-lg shadow-black mx-5 mt-5">
            <img src={Nylon5CloseEndps1} className="w-60" alt="" />
            <h3>Nylon #5 Close End (P/S) </h3>
          </div>

          <div className="text-center shadow-lg shadow-black mx-5 mt-5">
            <img src={Nylon5OpenEnd2Way} className="w-60" alt="" />
            <h3 className="pt-10">Nylon # 5 Open End 2Way (M/S)</h3>
          </div>
        </div>
        {/* five */}
        <div className="flex mx-auto">
          <div className="text-center shadow-lg shadow-black mx-5 mt-5">
            <img src={NylonWaterproof3CloseEnd} className="w-60" alt="" />
            <h3 className="pt-16">Nylon (Waterproof) #3 Close End </h3>
          </div>

          <div className="text-center shadow-lg shadow-black mx-5 mt-5">
            <img src={nylonSilverTeethClose} className="w-60" alt="" />
            <h3>
              Nylon (Silver Teeth) <br></br> # 3 Close End (P/S)
            </h3>
          </div>
        </div>

        {/* six */}
        <div className="flex mx-auto">
          <div className="text-center shadow-lg shadow-black mx-5 mt-5">
            <img src={NylonSilverTeeth5OpenEnd} className="w-60" alt="" />
            <h3>
              Nylon (Silver Teeth) <br></br> # 5 Open End (P/S){" "}
            </h3>
          </div>

          <div className="text-center shadow-lg shadow-black mx-5 mt-5">
            <img src={nylonWaterproof5OpenEnd} className="w-60" alt="" />
            <h3>
              Nylon (Waterproof) <br></br> #5 Open End (PS)
            </h3>
          </div>
        </div>
        {/* seven */}
        <div className="flex mx-auto">
          <div className="text-center shadow-lg shadow-black mx-5 mt-5">
            <img src={Nylon5CloseEnd} className="w-60" alt="" />
            <h3>
              Nylon #5 <br></br> Close End (M/S){" "}
            </h3>
          </div>

          <div className="text-center shadow-lg shadow-black mx-5 mt-5">
            <img src={Nylon5XForm} className="w-60" alt="" />
            <h3 className="pt-6">Nylon # 5X Form</h3>
          </div>
        </div>

        {/* nylon invilable  */}
        <h3 className="text-4xl  pt-20 text-center pb-5">
          NYLON INVISIBLE ZIPPER{" "}
        </h3>
        <p className="text-center text-1xl">
          Technical characteristics of the Nylon Invisible Zippers product
        </p>

        <p className="pt-5">
          Fully automated high-precision production equipment for invisible
          zippers. A invisible zipper is a type of wire that is sewn to hide the
          zipper teeth, only exposing the pull hand on the surface of the
          product, which disappears into the dress seams.
        </p>

        <p className="text-center pt-5">
          <span className="font-bold">Technology </span>
          <br></br> Nylon Invisible zipper<br></br> Available sizes: <br></br> 3
          (3mm)
          <br></br> Use for: <br></br>Women’s dresses | Skirts & many more
        </p>

        <div className="flex mx-auto">
          <div className="text-center shadow-lg shadow-black mx-5 mt-5">
            <img alt="" className="w-64 mx-auto  shadow-lg" src={nylonone} />
            <h3>Invisible Nylon #3 Close End </h3>
          </div>
        </div>
      </div>
      {/* start end */}
      {/* image part end */}

      <h3 className="text-4xl text-center pt-10 ">Forms & Variations</h3>
      <div>
        <img src={var1} className="w-1/2 mx-auto mt-10" alt="" />
      </div>
    </div>
  );
};

export default NylonZipper;
