import { createBrowserRouter } from "react-router-dom";
import Main from "../../layout/Main";
import Home from "../../component/Home/Home";
import Error404 from "../../Pages/Error404/Error404";
import Login from "../../Pages/Login/Login";
import Register from "../../Pages/Register/Register";
import AboutUs from "../../Pages/AboutUs/AboutUs";
import ContactUs from "../../Pages/ContactUs/ContactUs";
import PrivacyPolicy from "../../Pages/PrivacyPolicy/PrivacyPolicy";
import TermsOfCondition from "../../Pages/TermsOfCondition/TermsOfCondition";
import CookiePolicy from "../../Pages/CookiePolicy/CookiePolicy";
import Blog from "../../Pages/Blog/Blog";
import PrivateRoute from "../PrivateRoute/PrivateRoute";
import Profile from "../../Pages/Profile/profile";
import QualityControl from "../../Pages/QualityControl/QualityControl";
import MetalZipper from "../../Pages/MetalZipper/MetalZipper";
import NylonZipper from "../../Pages/NylonZipper/NylonZipper";
import Thread from "../../Pages/Thread/Thread";
import PlasticZipper from "../../Pages/PlasticZipper/PlasticZipper";
import SliderZipper from "../../Pages/SliderZipper/SliderZipper";
import History from "../../Pages/History/History";
import SSB from "../../Pages/SSB/SSB";
import Missionandvission from "../../Pages/MissionandVission/Missionandvission";
import Certificate from "../../Pages/Certificate/Certificate";
import UserManual from "../../Pages/UserManual/UserManual";

export const Routes = createBrowserRouter([
  {
    path: "/",
    element: <Main></Main>,
    children: [
      {
        path: "/",
        element: <Home></Home>,
      },
      {
        path: "/login",
        element: <Login></Login>,
      },
      {
        path: "/register",
        element: <Register></Register>,
      },
      {
        path: "/aboutus",
        element: <AboutUs></AboutUs>,
      },
      {
        path: "/contactus",
        element: <ContactUs></ContactUs>,
      },
      {
        path: "/privacypolicy",
        element: <PrivacyPolicy></PrivacyPolicy>,
      },
      {
        path: "/termsofcondition",
        element: <TermsOfCondition></TermsOfCondition>,
      },
      {
        path: "/cookiepolicy",
        element: <CookiePolicy></CookiePolicy>,
      },
      {
        path: "/blog",
        element: <Blog></Blog>,
      },

      {
        path: "/quality",
        element: <QualityControl></QualityControl>,
      },
      {
        path: "/metalzipper",
        element: <MetalZipper></MetalZipper>,
      },
      {
        path: "/nylonzipper",
        element: <NylonZipper></NylonZipper>,
      },
      {
        path: "/thread",
        element: <Thread></Thread>,
      },
      {
        path: "/plasticzipper",
        element: <PlasticZipper></PlasticZipper>,
      },
      {
        path: "/sliderzipper",
        element: <SliderZipper></SliderZipper>,
      },
      {
        path: "/history",
        element: <History></History>,
      },
      {
        path: "/ssb",
        element: <SSB></SSB>,
      },
      {
        path: "/mission",
        element: <Missionandvission></Missionandvission>,
      },
      {
        path: "/certificate",
        element: <Certificate></Certificate>,
      },
      {
        path: "/usermanual",
        element: <UserManual></UserManual>,
      },
      {
        path: "/profile",
        element: (
          <PrivateRoute>
            <Profile></Profile>
          </PrivateRoute>
        ),
      },
    ],
  },

  {
    path: "*",
    element: <Error404></Error404>,
  },
]);
