import React from 'react';
import ScrollToTop from 'react-scroll-to-top';

const Scroll = () => {
    return (
        <div>
            <ScrollToTop smooth  color="blue"	className='mb-20' />
        </div>
    );
};

export default Scroll;