import React from "react";
import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const UserManual = () => {
  return (
    <div className="pt-20 container mx-auto">
      <div className="mt-10 ">
        <h1 className="text-2xl font-bold uppercase text-center">
          User Manual
        </h1>
        <p className="text-1xl flex items-start   pt-2 text-slate-400">
          <span className="text-sky-400 px-4">
            <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
          </span>
          Due to the variations in strength values, chemical reactions, and
          appearance of zippers based on their types, it is important to provide
          the authorized personnel of the zipper company with information about
          the intended usage location before placing an order.
        </p>
        <p className="text-1xl flex items-start   pt-2 text-slate-400">
          <span className="text-sky-400 px-4">
            <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
          </span>
          The zipper on the product should be washed after it is fully closed.
        </p>
        <p className="text-1xl flex items-start   pt-2 text-slate-400">
          <span className="text-sky-400 px-4">
            <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
          </span>
          Washed zipper products should be dried quickly and should not be left
          wet. If the zipper on a wet product has been dried, it should be
          opened and closed.
        </p>
        <p className="text-1xl flex items-start   pt-2 text-slate-400">
          <span className="text-sky-400 px-4">
            <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
          </span>
          During the washing of zipper products, auxiliary chemical substances
          should be used in appropriate proportions, and the processing time
          should not exceed 30 minutes.
        </p>
        <p className="text-1xl flex items-start   pt-2 text-slate-400">
          <span className="text-sky-400 px-4">
            <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
          </span>
          It is essential to remember to wash products with zippers turned
          inside out as much as possible
        </p>
        <p className="text-1xl flex items-start   pt-2 text-slate-400">
          <span className="text-sky-400 px-4">
            <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
          </span>
          In pumice stone washes, the final rinse of the zipper product should
          be done thoroughly, and it should be dried immediately.
        </p>

        <p className="text-1xl flex items-start   pt-2 text-slate-400">
          <span className="text-sky-400 px-4">
            <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
          </span>
          It should not be overlooked that storing zippers and zipper products
          in unsuitable environments can cause issues with tapes and teeth. They
          should not be subjected to excessive humidity and high temperatures,
          and should not be placed under heavy weights that could compromise
          their appearance and damage the metal components
        </p>

        <p className="text-1xl flex items-start   pt-2 text-slate-400">
          <span className="text-sky-400 px-4">
            <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
          </span>
          After the zipper is sewn, if the product is steam-pressed or treated
          with chemicals, it should be cooled down and placed in polyethylene
          bags without delay. Prolonged storage in these bags without cooling
          down can lead to chemical reactions.
        </p>

        <p className="text-1xl flex items-start   pt-2 text-slate-400">
          <span className="text-sky-400 px-4">
            <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
          </span>
          The pressure and temperature applied while pressing or ironing the
          zipper product should be adjusted carefully. Improper ironing can
          deform the zipper structure.
        </p>

        <p className="text-1xl flex items-start   pt-2 text-slate-400">
          <span className="text-sky-400 px-4">
            <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
          </span>
          It should be noted that dark-colored zippers sewn onto light-colored
          garments may cause color transfer issues.
        </p>

        <p className="text-1xl flex items-start   pt-2 text-slate-400">
          <span className="text-sky-400 px-4">
            <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
          </span>
          No processes other than washing should be applied to zippers with
          coatings (oxide, tin, antique brass, etc.). It should be kept in mind
          that non-standard industrial processes can cause the coating to color
          the fabrics and its own tape
        </p>

        <p className="text-1xl flex items-start   pt-2 text-slate-400">
          <span className="text-sky-400 px-4">
            <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
          </span>
          Each zipper should be sewn using a specific zipper sewing foot
          corresponding to its type.
        </p>

        <p className="text-1xl flex items-start   pt-2 text-slate-400">
          <span className="text-sky-400 px-4">
            <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
          </span>
          During zipper sewing, care should be taken to prevent the entry of
          foreign objects such as threads, lint, and fabric between the zipper
          teeth. Especially in the production of bags, shoes, and boots, the use
          of adhesive materials or solutions during sewing can render the zipper
          teeth and slider unable to perform their function
        </p>

        <p className="text-1xl flex items-start   pt-2 text-slate-400">
          <span className="text-sky-400 px-4">
            <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
          </span>
          Avoid striking the nylon zipper teeth, especially during their sewing.
        </p>

        <p className="text-1xl flex items-start   pt-2 text-slate-400">
          <span className="text-sky-400 px-4">
            <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
          </span>
          In baby and children's products, where needle breakage is checked,
          sliders of zippers should be manufactured using the non-magnetic
          method to pass through detectors without triggering an alarm.
        </p>

        <p className="text-1xl flex items-start   pt-2 text-slate-400">
          <span className="text-sky-400 px-4">
            <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
          </span>
          During zipper sewing, the path for the slider's smooth movement should
          be left open.
        </p>

        <p className="text-1xl flex items-start   pt-2 text-slate-400">
          <span className="text-sky-400 px-4">
            <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
          </span>
          Longitudinal cutting should not be made on the strips before sewing
          zippers. If done so, the warp threads of the strip will disperse,
          rendering the zipper unusable.
        </p>

        <p className="text-1xl flex items-start   pt-2 text-slate-400">
          <span className="text-sky-400 px-4">
            <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
          </span>
          To maximize corrosion resistance, it is recommended to prefer
          lacquered components.
        </p>

        <p className="text-1xl flex items-start   pt-2 text-slate-400">
          <span className="text-sky-400 px-4">
            <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
          </span>
          In separable zipper types, two side strips of different zippers should
          not be paired together, even if they have the same color and length.
          This can result in cosmetic flaws such as buckling.
        </p>

        <p className="text-1xl flex items-start   pt-2 text-slate-400">
          <span className="text-sky-400 px-4">
            <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
          </span>
          If spray-painted handles are used as accessories on zippers, there may
          be deformation on the swinging handle due to movement and impact
          during washing. To prevent this, the handles should be fixed or worn
          after washing.
        </p>

        <p className="text-1xl flex items-start   pt-2 text-slate-400">
          <span className="text-sky-400 px-4">
            <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
          </span>
          Due to the structure of zippers, which consist of metal, monofilament,
          plastic, accessories, and coatings, it is recommended by SSB ZIPPER to
          adhere to the rules of washing, protection, and appropriate usage area
          for their healthy functioning and long-term use to the maximum extent
          possible.
        </p>
      </div>
    </div>
  );
};

export default UserManual;
