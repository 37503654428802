import React from "react";
import "./metalzipper.css";
import metalone from "../../assets/images/metal zipper/MZ 4.5YG.jpg";
// import metaltwo from "../../assets/images/metal zipper/Metal Zipper 2.jpg";
/* import metalthree from "../../assets/images/metal zipper/METAL ZIPPER SSB.jpg";
import metalfour from "../../assets/images/metal zipper/Metal Zipper Gold Pic.jpg"; */
import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import metal3closeend from "../../assets/images/metal zipper/1. 3 Metal Close End.jpg";
import metal4closeend from "../../assets/images/metal zipper/2. 4 Metal Close End.jpg";
import metallarge from "../../assets/images/metal zipper/METAL ZIPPER FORMS & VARIATIONS.png";
import YG45YG from "../../assets/images/metal zipper/3. 4.5 Metal Close End NEW.jpg";
import YG4YG4 from "../../assets/images/metal zipper/4. 4YG Metal Close End.jpg";
import useTitle from "../../hooks/useTitle";
import metal5yz from "../../assets/images/metal zipper/5. 5YG Metal Close End.jpg";
import metal5 from "../../assets/images/metal zipper/6. 5 Metal Close End.jpg";
import metal5openend from "../../assets/images/metal zipper/8. 5 Metal Open End.jpg";
import metal5openend2way from "../../assets/images/metal zipper/5 Metal Open End 2Way.jpg";

const MetalZipper = () => {
  useTitle("Metal Zipper");
  return (
    <div className="pt-20 container mx-auto">
      {/* <p className="text-1xl pt-10 text-slate-400">
        SSB provides extensive coverage for athletic and high fashion brands,
        specializing in a variety of products such as ready-to-wear, outerwear,
        handbags, and luggage. Our expertise in zippers ensures top-notch
        quality through rigorous testing of Metal, Coil, or Molded Plastic
        zippers. What sets us apart is our dedication to working closely with
        customers to create specialty finishes and configurations that perfectly
        embody their unique style.
      </p> */}
      {/* 
      <h3 className="text-3xl text-black text-center pt-10 uppercase font-bold">
        Metal Zippers{" "}
      </h3> */}
      <div className="flex justify-center items-center pt-10">
        <img src={metalone} className="mgb mx-2 rounded-xl" alt="" />
        {/* <img src={metaltwo} className="w-80 mx-2" alt="" /> */}
      </div>
      <h3 className="text-3xl text-black text-center pt-10 uppercase font-bold">
        {" "}
        Metal Zippers
      </h3>
      {/* <div className="flex justify-center items-center pt-10">
        <img src={metalthree} className="w-80 mx-2" alt="" />
        <img src={metalfour} className="w-80 mx-2" alt="" />
      </div> */}

      <p className="text-1xl pt-10 text-slate-400">
        Metal zippers provide a refined and sturdy structure that can be
        physically felt. They are crafted from brass alloys that are resistant
        to tarnishing and built to last. Each tooth is made from high-quality
        flat brass wire and carefully mounted onto the tape, resulting in a
        square shape. The teeth are then polished to create a smooth chain that
        feels pleasant to the touch. These zippers are available in a variety of
        metal finishes that complement the brand's color scheme. These finishes
        are achieved through a chemical process that treats the zipper chain and
        matches the plating of the sliders and end stops.
      </p>

      <h3 className="text-3xl  text-left pt-10  font-bold">Features</h3>
      <p className="pt-5 pb-2 text-slate-400">
        <span className="text-sky-400 px-4">
          <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
        </span>
        Imported fadeless straight tape
      </p>
      <p className="pt-2 pb-2 text-slate-400">
        <span className="text-sky-400 px-4">
          <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
        </span>
        High color fastness
      </p>
      <p className="pt-2 pb-2 text-slate-400">
        <span className="text-sky-400 px-4">
          <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
        </span>
        Available visible chain, Enamel Painted & Plating color Slider
      </p>
      <p className="pt-2 pb-2 text-slate-400">
        <span className="text-sky-400 px-4">
          <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
        </span>
        High strength, Anti-oxidation and moisture resistance
      </p>
      <p className="pt-2 pb-2 text-slate-400">
        <span className="text-sky-400 px-4">
          <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
        </span>
        Abrasion, corrosion resistance, high temperature and spark resistance,
        non-magnetic
      </p>
      <p className="pt-2 pb-2 text-slate-400">
        <span className="text-sky-400 px-4">
          <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
        </span>
        Acid, alkali resistance
      </p>
      <p className="pt-2 pb-2 text-slate-400">
        <span className="text-sky-400 px-4">
          <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
        </span>
        Durable, dead soft, good ductility, High tensile strength, bright
        surface
      </p>
      <p className="pt-2 pb-2 text-slate-400">
        <span className="text-sky-400 px-4">
          <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
        </span>
        Lengthy functional life
      </p>
      <p className="pt-2 pb-2 text-slate-400">
        <span className="text-sky-400 px-4">
          <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
        </span>
        Plating color lacquer protection
      </p>
      <p className="pt-2 pb-2 text-slate-400">
        <span className="text-sky-400 px-4">
          <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
        </span>
        Low shrinkage
      </p>
      <p className="pt-2 pb-2 text-slate-400">
        <span className="text-sky-400 px-4">
          <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
        </span>
        Flawless finish
      </p>
      <p className="pt-2 pb-2 text-slate-400">
        <span className="text-sky-400 px-4">
          <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
        </span>
        AZO, Nickel, Lead, Phthalate, Magnet, Ferrous Free
      </p>

      {/* texxt */}
      <p className="text-1xl text-center pt-10">
        Technical characteristics of the Metal Zippers <br></br> In addition, a
        wide range of finishes, styles and sliders are also available.
      </p>

      <p className="text-center pt-5">
        <span className="font-bold">Technology: </span>
        <br></br> (Traditional square tooth in continuous chain).<br></br>{" "}
        Available sizes: <br></br> 3 (3mm) | 4 (4mm) | 5 (5mm) <br></br> Use
        for: <br></br> Pants| Jackets | Vests | Knitwear | Outwear | Casual
        Wear| Sports Wear| Bags | Shoes
      </p>

      <div className="grid grid-cols-1 pt-20">
        <div className="flex mx-auto">
          <div className="text-center shadow-lg shadow-black mx-5 mt-5">
            <img src={metal3closeend} className="w-60" alt="" />
            <h3>Metal #3 Close End</h3>
          </div>

          <div className="text-center shadow-lg shadow-black mx-5  mt-5">
            <img src={metal4closeend} className="w-60" alt="" />
            <h3>Metal #4Close End</h3>
          </div>
        </div>
        {/* 2 */}
        <div className="flex mx-auto">
          <div className="text-center shadow-lg shadow-black mx-5 mt-5">
            <img src={YG45YG} className="w-60" alt="" />
            <h3>Metal #4.5YG Close End</h3>
          </div>

          <div className="text-center shadow-lg shadow-black mx-5 mt-5">
            <img src={YG4YG4} className="w-60" alt="" />
            <h3>Metal # 4YG Close End </h3>
          </div>
        </div>
        {/* 3 */}
        <div className="flex mx-auto">
          <div className="text-center shadow-lg shadow-black mx-5 mt-5">
            <img src={metal5yz} className="w-60" alt="" />
            <h3>Metal #5YG Close End </h3>
          </div>

          <div className="text-center shadow-lg shadow-black mx-5 mt-5">
            <img src={metal5} className="w-60" alt="" />
            <h3> Metal # 5 Close End</h3>
          </div>
        </div>
        {/* 4 */}
        <div className="flex mx-auto">
          <div className="text-center shadow-lg shadow-black mx-5 mt-5">
            <img src={metal5openend} className="w-60" alt="" />
            <h3 className="pt-6">Metal #5 Open End </h3>
          </div>

          <div className="text-center shadow-lg shadow-black mx-5 mt-5">
            <img src={metal5openend2way} className="w-60" alt="" />
            <h3> Metal # 5 Open End 2way</h3>
          </div>
        </div>
      </div>
      <div className="pt-20">
        <h3 className="text-4xl text-center ">Forms & Variations</h3>

        <div className="">
          <img src={metallarge} className="w-1/2 mx-auto " alt="" />
        </div>
      </div>
    </div>
  );
};

export default MetalZipper;
