import Iframe from "react-iframe";
import ShowMoreText from "./ShowMoreText";
import ssbvid from "../../../assets/videos/2.SSB-Video.mp4";
const Category = () => {
  return (
    <div className=" container mx-auto mt-10">
      <h2 className="text-4xl font-bold uppercase pb-10">
        Welcome to <br></br> Fulpukuria Thread and Accessories Limited{" "}
      </h2>
      <div className="grid lg:grid-cols-2 grid-cols-1 ">
        <div>
          <ShowMoreText></ShowMoreText>
        </div>
        <div className="px-5">
          <video
            className="w-full"
            controls
            autoPlay
            loop
            muted
            src={ssbvid}
          ></video>
        </div>
      </div>
    </div>
  );
};

export default Category;
