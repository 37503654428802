import React, { useRef } from "react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./bannerslider.css";
import nylon from "../../../../assets/images/bannerslider/nylonzipper.jpg";
import metal from "../../../../assets/images/bannerslider/metal zipper.jpg";
import sewingzipper from "../../../../assets/images/bannerslider/sewing thread.jpg";
import plasticzipper from "../../../../assets/images/bannerslider/plasticzipper.jpg";
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { Swiper, SwiperSlide } from "swiper/react";

const BannerSlider = () => {
  const progressCircle = useRef(null);
  const progressContent = useRef(null);
  const onAutoplayTimeLeft = (s, time, progress) => {
    progressCircle.current.style.setProperty("--progress", 1 - progress);
    progressContent.current.textContent = `${Math.ceil(time / 2000)}s`;
  };
  return (
    <Swiper
      spaceBetween={30}
      centeredSlides={true}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
      }}
      navigation={true}
      modules={[Autoplay, Pagination, Navigation]}
      onAutoplayTimeLeft={onAutoplayTimeLeft}
      className="mySwiper"
    >
      <SwiperSlide>
        <img src={nylon} alt="Retouching" className="rounded-lg " />
      </SwiperSlide>
      <SwiperSlide>
        <img src={plasticzipper} alt="Retouching" className="rounded-lg " />
      </SwiperSlide>
      <SwiperSlide>
        <img src={metal} alt="Retouching" className="rounded-lg " />
      </SwiperSlide>
      <SwiperSlide>
        <img src={sewingzipper} alt="Retouching" className="rounded-lg " />
      </SwiperSlide>

      <div className="autoplay-progress " slot="container-end">
        <svg viewBox="0 0 48 48" ref={progressCircle}>
          <circle cx="24" cy="24" r="20"></circle>
        </svg>
        <span ref={progressContent}></span>
      </div>
    </Swiper>
  );
};

export default BannerSlider;
