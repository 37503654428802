import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../Shared/Header/Header.js";
import Footer from "../Shared/Footer/Footer.js";
// import HeaderTop from "../Shared/HeaderTop/HeaderTop.js";
// import Background from "../assets/images/floating_elements.png";
import "react-photo-view/dist/react-photo-view.css";
import { PhotoProvider } from "react-photo-view";
const Main = () => {
  return (
    // style={{ background: `url(${Background})` }}
    <div>
      <PhotoProvider>
        {/* <HeaderTop></HeaderTop> */}
        <Header></Header>
        <Outlet></Outlet>
        <Footer></Footer>
      </PhotoProvider>
    </div>
  );
};

export default Main;
