import React, { useState } from "react";

const ShowMoreText = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="text-xl text-left text-slate-400 space-y-5">
      <p>
        Established in 2006, Fulpukuria Thread Accessories Limited is a Sewing
        Thread manufacturing Company located in Dhaka, Bangladesh. As a company
        focused on exporting our products, we are fully committed to meeting the
        needs and requirements of our esteemed customers. In order to cater to
        their demands, we introduced the high-performance "SSB" brand Zipper in
        2017, utilizing state-of-the-art facilities and equipment.
      </p>

      <p>
        Our extensive product range includes Zipper & Thread, which is
        manufactured using premium quality non-hazardous raw materials and
        advanced machinery. We consistently upgrade our machinery to ensure that
        we possess cutting-edge production capabilities. Our primary objective
        is to provide prompt and efficient service at competitive prices, while
        also prioritizing environmental sustainability and the timely
        fulfillment of large orders.
      </p>

      {isExpanded && (
        <>
          <p>
            To maintain the highest standards, we have implemented individual
            Integrated Management System (IMS) that specifically focus on
            Quality, Safety, and Environment. These systems have been designed
            in accordance with internationally recognized standards such as
            ISO9001:2015 (Quality Management), ISO14001:2015 (Environmental
            Management System), and ISO45001:2015 (Occupational Health and
            Safety Management System).
          </p>

          <p>
            At Fulpukuria Thread Accessories Limited, we place great emphasis on
            quality control. Our in-house testing lab, overseen by experienced
            quality controllers, ensures that our raw materials, finished
            products, and non-finished products meet stringent international
            inspection standards such as Oeko-tex Standard-100, British BS3084,
            American ASTMD2061, and German DIN3419.
          </p>

          <p>
            Customer satisfaction is of utmost importance to us, and we are
            fully dedicated to forging a prosperous future together with our
            valued customers.
          </p>
        </>
      )}

      <div className="text-right">
        <button onClick={handleToggle} className="text-sky-400 underline">
          {isExpanded ? "Show Less" : "Show More"}
        </button>
      </div>
    </div>
  );
};

export default ShowMoreText;
