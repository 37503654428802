import React from "react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./Slider.css";

import one from "../../../../assets/images/brand/BERSHKA.png";
import two from "../../../../assets/images/brand/GIANT TIGER 2.png";
import three from "../../../../assets/images/brand/INDITEX.png";
import four from "../../../../assets/images/brand/KIK.png";
import five from "../../../../assets/images/brand/Kappa.png";
import six from "../../../../assets/images/brand/LPP.png";
import seven from "../../../../assets/images/brand/MINICONF.png";
import eight from "../../../../assets/images/brand/NORMA.png";
import nine from "../../../../assets/images/brand/PRIMRK.png";
import ten from "../../../../assets/images/brand/PULL & BER.png";
import eleben from "../../../../assets/images/brand/SUZY SHIER.png";
import twelb from "../../../../assets/images/brand/TEDDY SMITH 1.png";
import thirteen from "../../../../assets/images/brand/ZARA.png";
import fourteen from "../../../../assets/images/brand/ooji.png";

// Import required modules
import { Pagination, Autoplay } from "swiper";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { Swiper, SwiperSlide } from "swiper/react";

const Slider = () => {
  return (
    <Swiper
      slidesPerView={6}
      spaceBetween={30}
      pagination={{
        clickable: true,
      }}
      autoplay={{
        delay: 2000, // Set the delay for auto-slide in milliseconds
        disableOnInteraction: false, // Continue autoplay after user interaction
      }}
      modules={[Pagination, Autoplay]} // Include the Autoplay module
      className="mySwiper"
    >
      <SwiperSlide>
        <img src={one} alt="Retouching" className="rounded-lg  w-40" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={two} alt="Masking" className="rounded-lg  w-40" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={three} alt="Shadow" className="rounded-lg  w-40" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={four} alt="Neckjoint" className="rounded-lg  w-40" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={five} alt="Color Correction" className="rounded-lg  w-40" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={six} alt="Clipping Parh" className="rounded-lg  w-40" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={seven} alt="Background Remove" className="rounded-lg  w-40" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={eight} alt="Jewlary" className="rounded-lg  w-40" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={nine} alt="Jewlary" className="rounded-lg  w-40" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={ten} alt="Jewlary" className="rounded-lg  w-40" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={eleben} alt="Jewlary" className="rounded-lg  w-40" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={twelb} alt="Jewlary" className="rounded-lg  w-40" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={thirteen} alt="Jewlary" className="rounded-lg  w-40" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={fourteen} alt="Jewlary" className="rounded-lg  w-40" />
      </SwiperSlide>
    </Swiper>
  );
};

export default Slider;
