import React from "react";
import useTitle from "../../hooks/useTitle";
import SSB0001 from "../../assets/images/slider12/SSB0001.jpeg";
import SSB0002 from "../../assets/images/slider12/SSB0002.jpeg";
import SSB0003 from "../../assets/images/slider12/SSB0003.jpeg";
import SSB0004 from "../../assets/images/slider12/SSB0004.jpeg";
import SSB0005 from "../../assets/images/slider12/SSB0005.jpeg";
import SSB0006 from "../../assets/images/slider12/SSB0006.jpeg";
import SSB0007 from "../../assets/images/slider12/SSB0007.jpeg";
import SSB0008 from "../../assets/images/slider12/SSB0008.jpeg";
import SSB0009 from "../../assets/images/slider12/SSB0009.jpeg";
import SSB0010 from "../../assets/images/slider12/SSB0010.jpeg";
import SSB0011 from "../../assets/images/slider12/SSB0011.jpeg";
import SSB0012 from "../../assets/images/slider12/SSB0012.jpeg";
import SSB0013 from "../../assets/images/slider12/SSB0013.jpeg";
import SSB0014 from "../../assets/images/slider12/SSB0014.jpeg";
import SSB0015 from "../../assets/images/slider12/SSB0015.jpeg";
import SSB0016 from "../../assets/images/slider12/SSB0016.jpeg";
import SSB0017 from "../../assets/images/slider12/SSB0017.jpeg";
import SSB0018 from "../../assets/images/slider12/SSB0018.jpeg";
import SSB0019 from "../../assets/images/slider12/SSB0019.jpeg";
import SSB0020 from "../../assets/images/slider12/SSB0020.jpeg";
import SSB0021 from "../../assets/images/slider12/SSB0021.jpeg";
import SSB0022 from "../../assets/images/slider12/SSB0022.jpeg";
import SSB0023 from "../../assets/images/slider12/SSB0023.jpeg";
import SSB0024 from "../../assets/images/slider12/SSB0024.jpeg";
import SSB0025 from "../../assets/images/slider12/SSB0025.jpeg";
import SSB0026 from "../../assets/images/slider12/SSB0026.jpeg";
import SSB0027 from "../../assets/images/slider12/SSB0027.jpeg";
import SSB0028 from "../../assets/images/slider12/SSB0028.jpeg";
import SSB0029 from "../../assets/images/slider12/SSB0029.jpeg";
import SSB0030 from "../../assets/images/slider12/SSB0030.jpeg";
import SSB0031 from "../../assets/images/slider12/SSB0031.jpeg";
import SSB0032 from "../../assets/images/slider12/SSB0032.jpeg";
import SSB0033 from "../../assets/images/slider12/SSB0033.jpeg";
import SSB0034 from "../../assets/images/slider12/SSB0034.jpeg";
import SSB0035 from "../../assets/images/slider12/SSB0035.jpeg";
import SSB0036 from "../../assets/images/slider12/SSB0036.jpeg";
import SSB0037 from "../../assets/images/slider12/SSB0037.jpeg";
import SSB0038 from "../../assets/images/slider12/SSB0038.jpeg";
import SSB0039 from "../../assets/images/slider12/SSB0039.jpeg";
import SSB0040 from "../../assets/images/slider12/SSB0040.jpeg";
import SSB0041 from "../../assets/images/slider12/SSB0041.jpeg";
import SSB0042 from "../../assets/images/slider12/SSB0042.jpeg";
import SSB0043 from "../../assets/images/slider12/SSB0043.jpeg";
import SSB0044 from "../../assets/images/slider12/SSB0044.jpeg";
import SSB0045 from "../../assets/images/slider12/SSB0045.jpeg";
import SSB0046 from "../../assets/images/slider12/SSB0046.jpeg";
import SSB0047 from "../../assets/images/slider12/SSB0047.jpeg";
import SSB0048 from "../../assets/images/slider12/SSB0048.jpeg";
import SSB0049 from "../../assets/images/slider12/SSB0049.jpeg";
import SSB0050 from "../../assets/images/slider12/SSB0050.jpeg";
import SSB0051 from "../../assets/images/slider12/SSB0051.jpeg";
import SSB0052 from "../../assets/images/slider12/SSB0052.jpeg";
import SSB0053 from "../../assets/images/slider12/SSB0053.jpeg";
import SSB0054 from "../../assets/images/slider12/SSB0054.jpeg";
import SSB0055 from "../../assets/images/slider12/SSB0055.jpeg";
import SSB0056 from "../../assets/images/slider12/SSB0056.jpeg";
import SSB0057 from "../../assets/images/slider12/SSB0057.jpeg";
import SSB0058 from "../../assets/images/slider12/SSB0058.jpeg";
import SSB0059 from "../../assets/images/slider12/SSB0059.jpeg";
import SSB0060 from "../../assets/images/slider12/SSB0060.jpeg";
import SSB0061 from "../../assets/images/slider12/SSB0061.jpeg";
import SSB0062 from "../../assets/images/slider12/SSB0062.jpeg";
import SSB0063 from "../../assets/images/slider12/SSB0063.jpeg";
import SSB0064 from "../../assets/images/slider12/SSB0064.jpeg";
import SSB0065 from "../../assets/images/slider12/SSB0065.jpeg";
import SSB0066 from "../../assets/images/slider12/SSB0066.jpeg";
import SSB0067 from "../../assets/images/slider12/SSB0067.jpeg";
import SSB0068 from "../../assets/images/slider12/SSB0068.jpeg";
import SSB0069 from "../../assets/images/slider12/SSB0069.jpeg";
import SSB0070 from "../../assets/images/slider12/SSB0070.jpeg";
import SSB0071 from "../../assets/images/slider12/SSB0071.jpeg";
import SSB0072 from "../../assets/images/slider12/SSB0072.jpeg";
import SSB0073 from "../../assets/images/slider12/SSB0073.jpeg";
import SSB0074 from "../../assets/images/slider12/SSB0074.jpeg";
import SSB0075 from "../../assets/images/slider12/SSB0075.jpeg";
import SSB0076 from "../../assets/images/slider12/SSB0076.jpeg";
import SSB0077 from "../../assets/images/slider12/SSB0077.jpeg";
import SSB0078 from "../../assets/images/slider12/SSB0078.jpeg";
import SSB0079 from "../../assets/images/slider12/SSB0079.jpeg";
import SSB0080 from "../../assets/images/slider12/SSB0080.jpeg";
import SSB0081 from "../../assets/images/slider12/SSB0081.jpeg";
import SSB0082 from "../../assets/images/slider12/SSB0082.jpeg";
import SSB0083 from "../../assets/images/slider12/SSB0083.jpeg";
import SSB0084 from "../../assets/images/slider12/SSB0084.jpeg";
import SSB0085 from "../../assets/images/slider12/SSB0085.jpeg";
import SSB0086 from "../../assets/images/slider12/SSB0086.jpeg";
import SSB0087 from "../../assets/images/slider12/SSB0087.jpeg";
import SSB0088 from "../../assets/images/slider12/SSB0088.jpeg";
import SSB0089 from "../../assets/images/slider12/SSB0089.jpeg";
import SSB0090 from "../../assets/images/slider12/SSB0090.jpeg";
import SSB0091 from "../../assets/images/slider12/SSB0091.jpeg";
import SSB0092 from "../../assets/images/slider12/SSB0092.jpeg";
import SSB0093 from "../../assets/images/slider12/SSB0093.jpeg";
import SSB0094 from "../../assets/images/slider12/SSB0094.jpeg";
import SSB0095 from "../../assets/images/slider12/SSB0095.jpeg";
import SSB0096 from "../../assets/images/slider12/SSB0096.jpeg";
import SSB0097 from "../../assets/images/slider12/SSB0097.jpeg";
import SSB0098 from "../../assets/images/slider12/SSB0098.jpeg";
import SSB0099 from "../../assets/images/slider12/SSB0099.jpeg";
import SSB0100 from "../../assets/images/slider12/SSB0100.jpeg";
import SSB0101 from "../../assets/images/slider12/SSB0101.jpeg";
import SSB0102 from "../../assets/images/slider12/SSB0102.jpeg";
import SSB0103 from "../../assets/images/slider12/SSB0103.jpeg";
import SSB0104 from "../../assets/images/slider12/SSB0104.jpeg";
import SSB0105 from "../../assets/images/slider12/SSB0105.jpeg";
import SSB0106 from "../../assets/images/slider12/SSB0106.jpeg";
import SSB0107 from "../../assets/images/slider12/SSB0107.jpeg";
import SSB0108 from "../../assets/images/slider12/SSB0108.jpeg";
import SSB0109 from "../../assets/images/slider12/SSB0109.jpeg";
import SSB0110 from "../../assets/images/slider12/SSB0110.jpeg";
import SSB0111 from "../../assets/images/slider12/SSB0111.jpeg";
import SSB0112 from "../../assets/images/slider12/SSB0112.jpeg";
import SSB0113 from "../../assets/images/slider12/SSB0113.jpeg";
import SSB0114 from "../../assets/images/slider12/SSB0114.jpeg";
import SSB0115 from "../../assets/images/slider12/SSB0115.jpeg";
import SSB0116 from "../../assets/images/slider12/SSB0116.jpeg";
import SSB0117 from "../../assets/images/slider12/SSB0117.jpeg";
import SSB0118 from "../../assets/images/slider12/SSB0118.jpeg";
import SSB0119 from "../../assets/images/slider12/SSB0119.jpeg";
import SSB0120 from "../../assets/images/slider12/SSB0120.jpeg";
import SSB0121 from "../../assets/images/slider12/SSB0121.jpeg";
import SSB0122 from "../../assets/images/slider12/SSB0122.jpeg";
import SSB0123 from "../../assets/images/slider12/SSB0123.jpeg";
import SSB0124 from "../../assets/images/slider12/SSB0124.jpeg";
import SSB0125 from "../../assets/images/slider12/SSB0125.jpeg";
import SSB0126 from "../../assets/images/slider12/SSB0126.jpeg";

import slione from "../../assets/images/slider12/slider body/5 Nylon Regular Body.jpg";
import slitwo from "../../assets/images/slider12/slider body/5 Nylon Reversed Body.jpg";
import slithree from "../../assets/images/slider12/slider body/3 No Nylon YG Regular Body.jpg";
import slifour from "../../assets/images/slider12/slider body/3 No Nylon Reversed Body.jpg";
import slifive from "../../assets/images/slider12/slider body/5 Nylon Regular Body.jpg";
import slisix from "../../assets/images/slider12/slider body/5 Nylon Reversed Body.jpg";
import sliseven from "../../assets/images/slider12/slider body/3 No Vislon Body.jpg";
import slieight from "../../assets/images/slider12/slider body/5 Vislon Regular Body.jpg";
import slinine from "../../assets/images/slider12/slider body/5 Vislon Butterfly YG Body.jpg";
import sli10 from "../../assets/images/slider12//slider body/3 No Metal Body.jpg";
import sli11 from "../../assets/images/slider12/slider body/4.5 No Metal YG Body.jpg";
import sli12 from "../../assets/images/slider12/slider body/4 No Metal Body.jpg";
import sli13 from "../../assets/images/slider12/slider body/4 No Metal YG Body.jpg";
import sli14 from "../../assets/images/slider12/slider body/5 No Metal Body.jpg";
import sli15 from "../../assets/images/slider12/slider body/5 No Metal YG Body.jpg";

import SSB0128 from "../../assets/images/slider12/SSB0128.jpg";
import SSB0129 from "../../assets/images/slider12/SSB0129.jpg";
import SSB0130 from "../../assets/images/slider12/SSB0130.jpg";
import SSB0131 from "../../assets/images/slider12/SSB0131.jpg";
import SSB0132 from "../../assets/images/slider12/SSB0132.jpeg";
// import SSB0004 from "../../assets/images/slider12/SSB0004.jpeg";
import pul1 from "../../assets/images/slider12/PULLER LINK/3 No Flat Brass Link.jpg";
import pul2 from "../../assets/images/slider12/PULLER LINK/3 No Flat Zink Link.jpg";
import pul3 from "../../assets/images/slider12/PULLER LINK/3No Hook Link.jpg";
import pul4 from "../../assets/images/slider12/PULLER LINK/5 No Flat Zink Link-1.jpg";
import pul5 from "../../assets/images/slider12/PULLER LINK/5 No Flat Zink Link-2.jpg";
import pul6 from "../../assets/images/slider12/PULLER LINK/5 No Hook Link.jpg";

import ban1 from "../../assets/images/slider12/ban/slid.jpg";
import ban2 from "../../assets/images/slider12/ban/slid2.jpg";
import ban3 from "../../assets/images/slider12/ban/slid3.jpg";
import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SSB1001 from "../../assets/images/slider12/logo puler/SSB1001.jpg";
import SSB1002 from "../../assets/images/slider12/logo puler/SSB1002.jpg";
import SSB1003 from "../../assets/images/slider12/logo puler/SSB1003.jpg";
import SSB1004 from "../../assets/images/slider12/logo puler/SSB1004.jpg";
import SSB1005 from "../../assets/images/slider12/logo puler/SSB1005.jpg";
import SSB1006 from "../../assets/images/slider12/logo puler/SSB1006.jpg";
import SSB1007 from "../../assets/images/slider12/logo puler/SSB1007.jpg";
import SSB1008 from "../../assets/images/slider12/logo puler/SSB1008.jpg";
import SSB1009 from "../../assets/images/slider12/logo puler/SSB1009.jpg";
import SSB1010 from "../../assets/images/slider12/logo puler/SSB1010.jpg";
import SSB1011 from "../../assets/images/slider12/logo puler/SSB1011.jpg";
import SSB1012 from "../../assets/images/slider12/logo puler/SSB1012.jpg";
import SSB1013 from "../../assets/images/slider12/logo puler/SSB1013.jpg";
import SSB1014 from "../../assets/images/slider12/logo puler/SSB1014.jpg";
import SSB1015 from "../../assets/images/slider12/logo puler/SSB1015.jpg";
import SSB1016 from "../../assets/images/slider12/logo puler/SSB1016.jpg";
import SSB1017 from "../../assets/images/slider12/logo puler/SSB1017.jpg";
import SSB1018 from "../../assets/images/slider12/logo puler/SSB1018.jpg";
import SSB1019 from "../../assets/images/slider12/logo puler/SSB1019.jpg";
import SSB1020 from "../../assets/images/slider12/logo puler/SSB1020.jpg";
import SSB1021 from "../../assets/images/slider12/logo puler/SSB1021.jpg";
import SSB1022 from "../../assets/images/slider12/logo puler/SSB1022.jpg";
import SSB1023 from "../../assets/images/slider12/logo puler/SSB1023.jpg";
import SSB1024 from "../../assets/images/slider12/logo puler/SSB1024.jpg";
import SSB1025 from "../../assets/images/slider12/logo puler/SSB1025.jpg";
import SSB1026 from "../../assets/images/slider12/logo puler/SSB1026.jpg";
import SSB1027 from "../../assets/images/slider12/logo puler/SSB1027.jpg";
import SSB1028 from "../../assets/images/slider12/logo puler/SSB1028.jpg";
import SSB1029 from "../../assets/images/slider12/logo puler/SSB1029.jpg";
import SSB1030 from "../../assets/images/slider12/logo puler/SSB1030.jpg";
import SSB1031 from "../../assets/images/slider12/logo puler/SSB1031.jpg";
// import SSB1032 from "../../assets/images/slider12/logo puler/SSB1032.jpg";
import SSB1033 from "../../assets/images/slider12/logo puler/SSB1033.jpg";
import SSB1034 from "../../assets/images/slider12/logo puler/SSB1034.jpg";
import SSB1035 from "../../assets/images/slider12/logo puler/SSB1035.jpg";
import SSB1036 from "../../assets/images/slider12/logo puler/SSB1036.jpg";
import SSB1037 from "../../assets/images/slider12/logo puler/SSB1037.jpg";
import SSB1038 from "../../assets/images/slider12/logo puler/SSB1038.jpg";
import SSB1039 from "../../assets/images/slider12/logo puler/SSB1039.jpg";
import SSB1040 from "../../assets/images/slider12/logo puler/SSB1040.jpg";
import SSB1041 from "../../assets/images/slider12/logo puler/SSB1041.jpg";
import SSB1042 from "../../assets/images/slider12/logo puler/SSB1042.jpg";
import SSB1043 from "../../assets/images/slider12/logo puler/SSB1043.jpg";
import SSB1044 from "../../assets/images/slider12/logo puler/SSB1044.jpg";
import SSB1045 from "../../assets/images/slider12/logo puler/SSB1045.jpg";
import SSB1046 from "../../assets/images/slider12/logo puler/SSB1046.jpg";
import SSB1047 from "../../assets/images/slider12/logo puler/SSB1047.jpg";
import SSB1048 from "../../assets/images/slider12/logo puler/SSB1048.jpg";
import SSB1049 from "../../assets/images/slider12/logo puler/SSB1049.jpg";
import SSB1050 from "../../assets/images/slider12/logo puler/SSB1050.jpg";
import SSB1051 from "../../assets/images/slider12/logo puler/SSB1051.jpg";
import SSB1052 from "../../assets/images/slider12/logo puler/SSB1052.jpg";
import SSB1053 from "../../assets/images/slider12/logo puler/SSB1053.jpg";
import SSB1054 from "../../assets/images/slider12/logo puler/SSB1054.jpg";
import SSB1055 from "../../assets/images/slider12/logo puler/SSB1055.jpg";
import SSB1056 from "../../assets/images/slider12/logo puler/SSB1056.jpg";
import SSB1057 from "../../assets/images/slider12/logo puler/SSB1057.jpg";
import SSB1058 from "../../assets/images/slider12/logo puler/SSB1058.jpg";
import SSB1059 from "../../assets/images/slider12/logo puler/SSB1059.jpg";
import SSB1060 from "../../assets/images/slider12/logo puler/SSB1060.jpg";
import SSB1061 from "../../assets/images/slider12/logo puler/SSB1061.jpg";
import SSB1062 from "../../assets/images/slider12/logo puler/SSB1062.jpg";
import SSB1063 from "../../assets/images/slider12/logo puler/SSB1063.jpg";
import SSB1064 from "../../assets/images/slider12/logo puler/SSB1064.jpg";
import SSB1065 from "../../assets/images/slider12/logo puler/SSB1065.jpg";
import SSB1066 from "../../assets/images/slider12/logo puler/SSB1066.jpg";
import SSB1067 from "../../assets/images/slider12/logo puler/SSB1067.jpg";
import SSB1068 from "../../assets/images/slider12/logo puler/SSB1068.jpg";
import SSB1069 from "../../assets/images/slider12/logo puler/SSB1069.jpg";
import SSB1070 from "../../assets/images/slider12/logo puler/SSB1070.jpg";
import SSB1071 from "../../assets/images/slider12/logo puler/SSB1071.jpg";
import SSB1072 from "../../assets/images/slider12/logo puler/SSB1072.jpg";
import SSB1073 from "../../assets/images/slider12/logo puler/SSB1073.jpg";
import SSB1074 from "../../assets/images/slider12/logo puler/SSB1074.jpg";
import SSB1075 from "../../assets/images/slider12/logo puler/SSB1075.jpg";
import SSB1076 from "../../assets/images/slider12/logo puler/SSB1076.jpg";
import SSB1077 from "../../assets/images/slider12/logo puler/SSB1077.jpg";
import SSB1078 from "../../assets/images/slider12/logo puler/SSB1078.jpg";
import SSB1079 from "../../assets/images/slider12/logo puler/SSB1079.jpg";
import SSB1080 from "../../assets/images/slider12/logo puler/SSB1080.jpg";
import SSB1081 from "../../assets/images/slider12/logo puler/SSB1081.jpg";
import SSB1082 from "../../assets/images/slider12/logo puler/SSB1082.jpg";
import SSB1083 from "../../assets/images/slider12/logo puler/SSB1083.jpg";
import SSB1084 from "../../assets/images/slider12/logo puler/SSB1084.jpg";
import SSB1085 from "../../assets/images/slider12/logo puler/SSB1085.jpg";
import SSB1086 from "../../assets/images/slider12/logo puler/SSB1086.jpg";
import SSB1087 from "../../assets/images/slider12/logo puler/SSB1087.jpg";
import SSB1088 from "../../assets/images/slider12/logo puler/SSB1088.jpg";
import SSB1089 from "../../assets/images/slider12/logo puler/SSB1089.jpg";
import SSB1090 from "../../assets/images/slider12/logo puler/SSB1090.jpg";
import SSB1091 from "../../assets/images/slider12/logo puler/SSB1091.jpg";
import SSB1092 from "../../assets/images/slider12/logo puler/SSB1092.jpg";
import SSB1093 from "../../assets/images/slider12/logo puler/SSB1093.jpg";
import SSB1094 from "../../assets/images/slider12/logo puler/SSB1094.jpg";
import SSB1095 from "../../assets/images/slider12/logo puler/SSB1095.jpg";
import SSB1096 from "../../assets/images/slider12/logo puler/SSB1096.jpg";
import SSB1097 from "../../assets/images/slider12/logo puler/SSB1097.jpg";
import SSB1098 from "../../assets/images/slider12/logo puler/SSB1098.jpg";
import SSB1099 from "../../assets/images/slider12/logo puler/SSB1099.jpg";
import SSB1101 from "../../assets/images/slider12/logo puler/SSB1101.jpg";
import SSB1102 from "../../assets/images/slider12/logo puler/SSB1102.jpg";
import SSB1103 from "../../assets/images/slider12/logo puler/SSB1103.jpg";
import SSB1104 from "../../assets/images/slider12/logo puler/SSB1104.jpg";
import SSB1105 from "../../assets/images/slider12/logo puler/SSB1105.jpg";
import SSB1106 from "../../assets/images/slider12/logo puler/SSB1106.jpg";
import SSB1107 from "../../assets/images/slider12/logo puler/SSB1107.jpg";
import SSB1108 from "../../assets/images/slider12/logo puler/SSB1108.jpg";
import SSB1109 from "../../assets/images/slider12/logo puler/SSB1109.jpg";
import SSB1110 from "../../assets/images/slider12/logo puler/SSB1110.jpg";
// import SSB1111 from "../../assets/images/slider12/logo puler/
import SSB1112 from "../../assets/images/slider12/logo puler/SSB1112.jpg";
import SSB1113 from "../../assets/images/slider12/logo puler/SSB1113.jpg";
import SSB1114 from "../../assets/images/slider12/logo puler/SSB1114.jpg";
import SSB1115 from "../../assets/images/slider12/logo puler/SSB1115.jpg";
import SSB1116 from "../../assets/images/slider12/logo puler/SSB1116.jpg";
import SSB1117 from "../../assets/images/slider12/logo puler/SSB1117.jpg";
import SSB1118 from "../../assets/images/slider12/logo puler/SSB1118.jpg";

const SliderZipper = () => {
  useTitle("Slider Zipper");
  return (
    <div className="pt-40 container mx-auto">
      {/* banner */}
      <div className=" flex justify-center">
        <img src={ban2} alt="" className="w-60" />
        <img src={ban1} alt="" className="w-60" />
        <img src={ban3} alt="" className="w-60" />
      </div>

      {/* slider body */}
      <div className="pt-20 pb-20">
        <p className="text-slate-400">
          We ensure premium-quality sliders and pullers by seamlessly
          integrating in-house production, trusted partnerships, and top-tier
          imported sourcing.
        </p>

        <p className="text-slate-400 pt-5">
          <span className="font-bold text-black">Quality Control: </span>
          Precision molds and innovative puller designs crafted with expertise.
        </p>

        <p className="text-slate-400 pt-5">
          <span className="font-bold text-black">Design & Prototyping: </span>
          Efficiently handles high-volume orders within deadlines.
        </p>

        <p className="text-slate-400 pt-5">
          <span className="font-bold text-black">Scalable Production : </span>
          Efficiently handles high-volume orders within deadlines.
        </p>

        <h3 className="text-2xl font-black pt-2">Zipper Slider</h3>
        <p className="text-slate-400 pt-5 pl-2">
          <span className="font-bold text-black">Purpose: </span>
          Smoothly connects zipper chains.
        </p>

        <p className="text-slate-400 pt-5 pl-2">
          <span className="font-bold text-black">Materials: </span>
          Zinc alloy, Brass.
        </p>

        <h3 className="font-bold text-black pt-5 pl-2">Process :</h3>

        <p className="text-slate-400 pt-5 pl-14">
          <span className="font-bold text-sky-400">
            <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>{" "}
          </span>
          <span className="text-black font-bold "> Molding:</span> Die-casting
          for metals; injection molding for plastics.
        </p>
        {/* 2 */}
        <p className="text-slate-400 pt-5 pl-14">
          <span className="font-bold text-sky-400">
            <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>{" "}
          </span>
          <span className="text-black font-bold ">Polishing:</span>
          Removes burrs and smoothens surfaces.
        </p>
        {/* 3 */}
        <p className="text-slate-400 pt-5 pl-14">
          <span className="font-bold text-sky-400">
            <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>{" "}
          </span>
          <span className="text-black font-bold "> Finishing:</span>
          Plating or painting for durability and aesthetics
        </p>
        {/* 4 */}
        <p className="text-slate-400 pt-5 pl-14">
          <span className="font-bold text-sky-400">
            <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>{" "}
          </span>
          <span className="text-black font-bold "> Assembly:</span>
          Combines slider body with springs or locks.
        </p>
      </div>
      {/* zipper poller */}
      <h3 className="text-2xl font-black pt-2">Zipper Puller</h3>
      <p className="text-slate-400 pt-5 pl-2">
        <span className="font-bold text-black">Purpose: </span>
        Provides a grip to operate the slider.
      </p>

      <p className="text-slate-400 pt-5 pl-2">
        <span className="font-bold text-black">Materials: </span>
        Zinc alloy, Brass, Aluminum, Rubber, or Plastic
      </p>

      <h3 className="font-bold text-black pt-5 pl-2">Process :</h3>

      <p className="text-slate-400 pt-5 pl-14">
        <span className="font-bold text-sky-400">
          <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>{" "}
        </span>
        <span className="text-black font-bold "> Shaping: </span> Die-casting
        for Die-casting, stamping, or molding for form.
      </p>
      {/* 2 */}
      <p className="text-slate-400 pt-5 pl-14">
        <span className="font-bold text-sky-400">
          <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>{" "}
        </span>
        <span className="text-black font-bold ">Finishing:</span>
        Polished, plated, or enameled for strength and style.
      </p>
      {/* 3 */}
      <p className="text-slate-400 pt-5 pl-14">
        <span className="font-bold text-sky-400">
          <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>{" "}
        </span>
        <span className="text-black font-bold "> Attachment:</span>
        Connected to sliders with hooks, loops, or rings.
      </p>
      {/* 4 */}
      <p className="text-slate-400 pt-5 pl-14">
        <span className="font-bold text-sky-400">
          <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>{" "}
        </span>
        <span className="text-black font-bold "> Assembly:</span>
        Combines slider body with springs or locks.
      </p>
      {/* slider body end */}

      <h3 className="text-center text-4xl font-bold pb-5">SLIDER BODY</h3>
      <div className="grid lg:grid-cols-4 grid-cols-2 pb-10">
        {/* one */}
        <div className="text-center">
          <img src={slione} alt="" />
          <h4>3 No Nylon Regular Body</h4>
        </div>
        <div className="pl-2 text-center">
          <img src={slitwo} alt="" />
          <h4>3 No Nylon Reversed Body</h4>
        </div>
        <div className="pl-2 text-center">
          <img src={slithree} alt="" />
          <h4>3 No Nylon YG Regular Body</h4>
        </div>
        <div className="pl-2 text-center">
          <img src={slifour} alt="" />
          <h4>3 No Nylon YG Reversed Body</h4>
        </div>
        <div className="pl-2 text-center">
          <img src={slifive} alt="" />
          <h4>5 Nylon Regular Body</h4>
        </div>
        {/* two */}
        <div className="pl-2 text-center">
          <img src={slisix} alt="" />
          <h4>5 Nylon Reversed Body</h4>
        </div>
        <div className="pl-2 text-center">
          <img src={sliseven} alt="" />
          <h4>3 No Vislon Body</h4>
        </div>
        <div className="pl-2 text-center">
          <img src={slieight} alt="" />
          <h4>5 Vislon Regular Body</h4>
        </div>
        <div className="pl-2 text-center">
          <img src={slinine} alt="" />
          <h4>5 Vislon Butterfly YG Body</h4>
        </div>
        <div className="pl-2 text-center">
          <img src={sli10} alt="" />
          <h4>3 No Metal Body</h4>
        </div>
        {/* three */}
        <div className="pl-2 text-center">
          <img src={sli11} alt="" />
          <h4>4.5 No Metal YG Body</h4>
        </div>
        <div className="pl-2 text-center">
          <img src={sli12} alt="" />
          <h4>4 No Metal Body</h4>
        </div>
        <div className="pl-2 text-center">
          <img src={sli13} alt="" />
          <h4>4 No Metal YG Body</h4>
        </div>
        <div className="pl-2 text-center">
          <img src={sli14} alt="" />
          <h4>5 No Metal Body</h4>
        </div>
        <div className="pl-2 text-center">
          <img src={sli15} alt="" />
          <h4>5 No Metal YG Body</h4>
        </div>
        {/* four */}
      </div>

      {/* puller link */}
      <h3 className="text-4xl font-bold text-center">PULLER LINK</h3>
      <div className="grid lg:grid-cols-4  grid-cols-2 pt-20">
        <div className="text-center">
          <img src={pul1} alt="" />
          <h4>3 No Flat Brass Link</h4>
        </div>
        <div className="pl-2 text-center">
          <img src={pul2} alt="" />
          <h4>3 No Flat Zink Link</h4>
        </div>
        <div className="pl-2 text-center">
          <img src={pul3} alt="" />
          <h4>3No Hook Link</h4>
        </div>
        <div className="pl-2 text-center">
          <img src={pul4} alt="" />
          <h4>5 No Flat Zink Link-1</h4>
        </div>
        <div className="pl-2 text-center">
          <img src={pul5} alt="" />
          <h4>5 No Flat Zink Link-2</h4>
        </div>

        <div className="pl-2 text-center">
          <img src={pul6} alt="" />
          <h4>5 No Hook Link</h4>
        </div>
      </div>
      {/* SSB NON LOGO PULLER */}
      <h3 className="text-4xl font-bold text-center pt-20">
        SSB NON LOGO PULLER
      </h3>

      <div className="grid lg:grid-cols-4  grid-cols-2 pt-20">
        {/* one */}
        <div className="text-center  ">
          <img src={SSB0001} className="p-2" alt="" />
          <h3>SSB0001 </h3>
        </div>

        <div className="text-center ">
          <img src={SSB0002} className="p-2" alt="" />
          <h3>SSB0002 </h3>
        </div>

        <div className="text-center ">
          <img src={SSB0003} className="p-2" alt="" />
          <h3>SSB0003 </h3>
        </div>

        <div className="text-center ">
          <img src={SSB0004} className="p-2" alt="" />
          <h3>SSB0004 </h3>
        </div>

        <div className="text-center ">
          <img src={SSB0005} className="p-2" alt="" />
          <h3>SSB0005 </h3>
        </div>

        <div className="text-center ">
          <img src={SSB0006} className="p-2" alt="" />
          <h3>SSB0006 </h3>
        </div>

        <div className="text-center ">
          <img src={SSB0007} className="p-2" alt="" />
          <h3>SSB0007 </h3>
        </div>

        <div className="text-center ">
          <img src={SSB0008} className="p-2" alt="" />
          <h3>SSB0008 </h3>
        </div>

        <div className="text-center ">
          <img src={SSB0009} className="p-2" alt="" />
          <h3>SSB0009 </h3>
        </div>

        <div className="text-center ">
          <img src={SSB0010} className="p-2" alt="" />
          <h3>SSB0010 </h3>
        </div>

        <div className="text-center ">
          <img src={SSB0011} className="p-2" alt="" />
          <h3>SSB0011 </h3>
        </div>

        <div className="text-center ">
          <img src={SSB0012} className="p-2" alt="" />
          <h3>SSB0012 </h3>
        </div>

        <div className="text-center ">
          <img src={SSB0013} className="p-2" alt="" />
          <h3>SSB0013 </h3>
        </div>

        <div className="text-center ">
          <img src={SSB0014} className="p-2" alt="" />
          <h3>SSB0014 </h3>
        </div>

        <div className="text-center ">
          <img src={SSB0015} className="p-2" alt="" />
          <h3>SSB0015 </h3>
        </div>

        <div className="text-center ">
          <img src={SSB0016} className="p-2" alt="" />
          <h3>SSB0016 </h3>
        </div>

        <div className="text-center ">
          <img src={SSB0017} className="p-2" alt="" />
          <h3>SSB0017 </h3>
        </div>

        <div className="text-center ">
          <img src={SSB0018} className="p-2" alt="" />
          <h3>SSB0018 </h3>
        </div>

        <div className="text-center ">
          <img src={SSB0019} className="p-2" alt="" />
          <h3>SSB0019 </h3>
        </div>

        <div className="text-center ">
          <img src={SSB0020} className="p-2" alt="" />
          <h3>SSB0020 </h3>
        </div>

        <div className="text-center ">
          <img src={SSB0021} className="p-2" alt="" />
          <h3>SSB0021 </h3>
        </div>

        <div className="text-center ">
          <img src={SSB0022} className="p-2" alt="" />
          <h3>SSB0022 </h3>
        </div>

        <div className="text-center ">
          <img src={SSB0023} className="p-2" alt="" />
          <h3>SSB0023 </h3>
        </div>

        <div className="text-center ">
          <img src={SSB0024} className="p-2" alt="" />
          <h3>SSB0024 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0025} className="p-2" alt="" />
          <h3>SSB0025 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0026} className="p-2" alt="" />
          <h3>SSB0026 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0027} className="p-2" alt="" />
          <h3>SSB0027 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0028} className="p-2" alt="" />
          <h3>SSB0028 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0029} className="p-2" alt="" />
          <h3>SSB0029 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0030} className="p-2" alt="" />
          <h3>SSB0030 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0031} className="p-2" alt="" />
          <h3>SSB0032 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0033} className="p-2" alt="" />
          <h3>SSB0033 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0034} className="p-2" alt="" />
          <h3>SSB0034 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0035} className="p-2" alt="" />
          <h3>SSB0035 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0036} className="p-2" alt="" />
          <h3>SSB0036 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0037} className="p-2" alt="" />
          <h3>SSB0037 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0038} className="p-2" alt="" />
          <h3>SSB0038 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0039} className="p-2" alt="" />
          <h3>SSB0039 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0040} className="p-2" alt="" />
          <h3>SSB0040 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0041} className="p-2" alt="" />
          <h3>SSB0041 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0042} className="p-2" alt="" />
          <h3>SSB0042 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0043} className="p-2" alt="" />
          <h3>SSB0043 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0044} className="p-2" alt="" />
          <h3>SSB0044 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0045} className="p-2" alt="" />
          <h3>SSB0045 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0046} className="p-2" alt="" />
          <h3>SSB0046 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0047} className="p-2" alt="" />
          <h3>SSB0047 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0048} className="p-2" alt="" />
          <h3>SSB0048 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0049} className="p-2" alt="" />
          <h3>SSB0049 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0050} className="p-2" alt="" />
          <h3>SSB0050 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0051} className="p-2" alt="" />
          <h3>SSB0051 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0052} className="p-2" alt="" />
          <h3>SSB0052 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0053} className="p-2" alt="" />
          <h3>SSB0053 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0054} className="p-2" alt="" />
          <h3>SSB0054 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0055} className="p-2" alt="" />
          <h3>SSB0055 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0056} className="p-2" alt="" />
          <h3>SSB0056 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0057} className="p-2" alt="" />
          <h3>SSB0057 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0058} className="p-2" alt="" />
          <h3>SSB0058 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0059} className="p-2" alt="" />
          <h3>SSB0059 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0060} className="p-2" alt="" />
          <h3>SSB0060 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0061} className="p-2" alt="" />
          <h3>SSB0061</h3>
        </div>
        <div className="text-center ">
          <img src={SSB0062} className="p-2" alt="" />
          <h3>SSB0062 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0063} className="p-2" alt="" />
          <h3>SSB0063 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0064} className="p-2" alt="" />
          <h3>SSB0064 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0065} className="p-2" alt="" />
          <h3>SSB0065 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0066} className="p-2" alt="" />
          <h3>SSB0066 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0067} className="p-2" alt="" />
          <h3>SSB0067 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0068} className="p-2" alt="" />
          <h3>SSB0068 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0069} className="p-2" alt="" />
          <h3>SSB0069 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0070} className="p-2" alt="" />
          <h3>SSB0070</h3>
        </div>
        <div className="text-center ">
          <img src={SSB0071} className="p-2" alt="" />
          <h3>SSB0071 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0072} className="p-2" alt="" />
          <h3>SSB0072 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0073} className="p-2" alt="" />
          <h3>SSB0073 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0074} className="p-2" alt="" />
          <h3>SSB0074 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0075} className="p-2" alt="" />
          <h3>SSB0075 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0076} className="p-2" alt="" />
          <h3>SSB0076 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0077} className="p-2" alt="" />
          <h3>SSB0078 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0078} className="p-2" alt="" />
          <h3>SSB0078 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0079} className="p-2" alt="" />
          <h3>SSB0079 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0080} className="p-2" alt="" />
          <h3>SSB0080 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0081} className="p-2" alt="" />
          <h3>SSB0081 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0082} className="p-2" alt="" />
          <h3>SSB0082 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0083} className="p-2" alt="" />
          <h3>SSB0083 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0084} className="p-2" alt="" />
          <h3>SSB0084 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0085} className="p-2" alt="" />
          <h3>SSB0085 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0086} className="p-2" alt="" />
          <h3>SSB0086 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0087} className="p-2" alt="" />
          <h3>SSB0087 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0088} className="p-2" alt="" />
          <h3>SSB0088 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0089} className="p-2" alt="" />
          <h3>SSB0089 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0090} className="p-2" alt="" />
          <h3>SSB0090 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0091} className="p-2" alt="" />
          <h3>SSB0091 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0092} className="p-2" alt="" />
          <h3>SSB0092 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0093} className="p-2" alt="" />
          <h3>SSB0093 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0094} className="p-2" alt="" />
          <h3>SSB0094 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0095} className="p-2" alt="" />
          <h3>SSB0095 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0096} className="p-2" alt="" />
          <h3>SSB0096 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0097} className="p-2" alt="" />
          <h3>SSB0097 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0098} className="p-2" alt="" />
          <h3>SSB0098 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0098} className="p-2" alt="" />
          <h3>SSB0098 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0099} className="p-2" alt="" />
          <h3>SSB0099 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0100} className="p-2" alt="" />
          <h3>SSB0100 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0101} className="p-2" alt="" />
          <h3>SSB0102 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0102} className="p-2" alt="" />
          <h3>SSB0102 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0103} className="p-2" alt="" />
          <h3>SSB0103 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0104} className="p-2" alt="" />
          <h3>SSB0104 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0105} className="p-2" alt="" />
          <h3>SSB0105 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0106} className="p-2" alt="" />
          <h3>SSB0106 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0107} className="p-2" alt="" />
          <h3>SSB0107 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0108} className="p-2" alt="" />
          <h3>SSB0108 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0109} className="p-2" alt="" />
          <h3>SSB0109 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0110} className="p-2" alt="" />
          <h3>SSB0110 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0111} className="p-2" alt="" />
          <h3>SSB0111 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0112} className="p-2" alt="" />
          <h3>SSB0112 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0113} className="p-2" alt="" />
          <h3>SSB0114</h3>
        </div>
        <div className="text-center ">
          <img src={SSB0114} className="p-2" alt="" />
          <h3>SSB0114 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0115} className="p-2" alt="" />
          <h3>SSB0115 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0116} className="p-2" alt="" />
          <h3>SSB0116 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0117} className="p-2" alt="" />
          <h3>SSB0117 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0118} className="p-2" alt="" />
          <h3>SSB0118 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0119} className="p-2" alt="" />
          <h3>SSB0119 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0120} className="p-2" alt="" />
          <h3>SSB0120 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0121} className="p-2" alt="" />
          <h3>SSB0121 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0122} className="p-2" alt="" />
          <h3>SSB0122 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0123} className="p-2" alt="" />
          <h3>SSB0123 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0124} className="p-2" alt="" />
          <h3>SSB0124 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0125} className="p-2" alt="" />
          <h3>SSB0125 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0126} className="p-2" alt="" />
          <h3>SSB0126 </h3>
        </div>

        <div className="text-center ">
          <img src={SSB0128} className="p-2" alt="" />
          <h3>SSB0128 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0129} className="p-2" alt="" />
          <h3>SSB0129 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0130} className="p-2" alt="" />
          <h3>SSB0130 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0131} className="p-2" alt="" />
          <h3>SSB0131 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB0132} className="p-2" alt="" />
          <h3>SSB0132 </h3>
        </div>

        {/* 2 */}
      </div>
      {/* SSB NON LOGO PULLER */}
      <h3 className="text-4xl font-bold text-center pt-20">SSB LOGO PULLER</h3>

      <div className="grid lg:grid-cols-4  grid-cols-2 pt-20">
        {/* one */}
        <div className="text-center  ">
          <img src={SSB1001} className="p-2" alt="" />
          <h3>SSB1001 </h3>
        </div>

        <div className="text-center ">
          <img src={SSB1002} className="p-2" alt="" />
          <h3>SSB1002 </h3>
        </div>

        <div className="text-center ">
          <img src={SSB1003} className="p-2" alt="" />
          <h3>SSB1003 </h3>
        </div>

        <div className="text-center ">
          <img src={SSB1004} className="p-2" alt="" />
          <h3>SSB1004 </h3>
        </div>

        <div className="text-center ">
          <img src={SSB1005} className="p-2" alt="" />
          <h3>SSB1005 </h3>
        </div>

        <div className="text-center ">
          <img src={SSB1006} className="p-2" alt="" />
          <h3>SSB1006 </h3>
        </div>

        <div className="text-center ">
          <img src={SSB1007} className="p-2" alt="" />
          <h3>SSB1007 </h3>
        </div>

        <div className="text-center ">
          <img src={SSB1008} className="p-2" alt="" />
          <h3>SSB1008 </h3>
        </div>

        <div className="text-center ">
          <img src={SSB1009} className="p-2" alt="" />
          <h3>SSB1009 </h3>
        </div>

        <div className="text-center ">
          <img src={SSB1010} className="p-2" alt="" />
          <h3>SSB1010 </h3>
        </div>

        <div className="text-center ">
          <img src={SSB1011} className="p-2" alt="" />
          <h3>SSB1011 </h3>
        </div>

        <div className="text-center ">
          <img src={SSB1012} className="p-2" alt="" />
          <h3>SSB1012 </h3>
        </div>

        <div className="text-center ">
          <img src={SSB1013} className="p-2" alt="" />
          <h3>SSB1013 </h3>
        </div>

        <div className="text-center ">
          <img src={SSB1014} className="p-2" alt="" />
          <h3>SSB1014 </h3>
        </div>

        <div className="text-center ">
          <img src={SSB1015} className="p-2" alt="" />
          <h3>SSB1015 </h3>
        </div>

        <div className="text-center ">
          <img src={SSB1016} className="p-2" alt="" />
          <h3>SSB1016 </h3>
        </div>

        <div className="text-center ">
          <img src={SSB1017} className="p-2" alt="" />
          <h3>SSB1017 </h3>
        </div>

        <div className="text-center ">
          <img src={SSB1018} className="p-2" alt="" />
          <h3>SSB1018 </h3>
        </div>

        <div className="text-center ">
          <img src={SSB1019} className="p-2" alt="" />
          <h3>SSB1019 </h3>
        </div>

        <div className="text-center ">
          <img src={SSB1020} className="p-2" alt="" />
          <h3>SSB1020 </h3>
        </div>

        <div className="text-center ">
          <img src={SSB1021} className="p-2" alt="" />
          <h3>SSB1021 </h3>
        </div>

        <div className="text-center ">
          <img src={SSB1022} className="p-2" alt="" />
          <h3>SSB1022 </h3>
        </div>

        <div className="text-center ">
          <img src={SSB1023} className="p-2" alt="" />
          <h3>SSB1023 </h3>
        </div>

        <div className="text-center ">
          <img src={SSB1024} className="p-2" alt="" />
          <h3>SSB1024 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1025} className="p-2" alt="" />
          <h3>SSB1025 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1026} className="p-2" alt="" />
          <h3>SSB1026 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1027} className="p-2" alt="" />
          <h3>SSB1027 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1028} className="p-2" alt="" />
          <h3>SSB1028 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1029} className="p-2" alt="" />
          <h3>SSB1029 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1030} className="p-2" alt="" />
          <h3>SSB1030 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1031} className="p-2" alt="" />
          <h3>SSB1032 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1033} className="p-2" alt="" />
          <h3>SSB1033 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1034} className="p-2" alt="" />
          <h3>SSB1034 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1035} className="p-2" alt="" />
          <h3>SSB1035 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1036} className="p-2" alt="" />
          <h3>SSB1036 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1037} className="p-2" alt="" />
          <h3>SSB1037 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1038} className="p-2" alt="" />
          <h3>SSB1038 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1039} className="p-2" alt="" />
          <h3>SSB1039 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1040} className="p-2" alt="" />
          <h3>SSB1040 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1041} className="p-2" alt="" />
          <h3>SSB1041 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1042} className="p-2" alt="" />
          <h3>SSB1042 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1043} className="p-2" alt="" />
          <h3>SSB1043 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1044} className="p-2" alt="" />
          <h3>SSB1044 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1045} className="p-2" alt="" />
          <h3>SSB1045 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1046} className="p-2" alt="" />
          <h3>SSB1046 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1047} className="p-2" alt="" />
          <h3>SSB1047 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1048} className="p-2" alt="" />
          <h3>SSB1048 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1049} className="p-2" alt="" />
          <h3>SSB1049 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1050} className="p-2" alt="" />
          <h3>SSB1050 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1051} className="p-2" alt="" />
          <h3>SSB1051 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1052} className="p-2" alt="" />
          <h3>SSB1052 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1053} className="p-2" alt="" />
          <h3>SSB1053 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1054} className="p-2" alt="" />
          <h3>SSB1054 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1055} className="p-2" alt="" />
          <h3>SSB1055 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1056} className="p-2" alt="" />
          <h3>SSB1056 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1057} className="p-2" alt="" />
          <h3>SSB1057 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1058} className="p-2" alt="" />
          <h3>SSB1058 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1059} className="p-2" alt="" />
          <h3>SSB1059 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1060} className="p-2" alt="" />
          <h3>SSB1060 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1061} className="p-2" alt="" />
          <h3>SSB1061</h3>
        </div>
        <div className="text-center ">
          <img src={SSB1062} className="p-2" alt="" />
          <h3>SSB1062 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1063} className="p-2" alt="" />
          <h3>SSB1063 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1064} className="p-2" alt="" />
          <h3>SSB1064 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1065} className="p-2" alt="" />
          <h3>SSB1065 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1066} className="p-2" alt="" />
          <h3>SSB1066 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1067} className="p-2" alt="" />
          <h3>SSB1067 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1068} className="p-2" alt="" />
          <h3>SSB1068 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1069} className="p-2" alt="" />
          <h3>SSB1069 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1070} className="p-2" alt="" />
          <h3>SSB1070</h3>
        </div>
        <div className="text-center ">
          <img src={SSB1071} className="p-2" alt="" />
          <h3>SSB1071 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1072} className="p-2" alt="" />
          <h3>SSB1072 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1073} className="p-2" alt="" />
          <h3>SSB1073 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1074} className="p-2" alt="" />
          <h3>SSB1074 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1075} className="p-2" alt="" />
          <h3>SSB1075 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1076} className="p-2" alt="" />
          <h3>SSB1076 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1077} className="p-2" alt="" />
          <h3>SSB1078 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1078} className="p-2" alt="" />
          <h3>SSB1078 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1079} className="p-2" alt="" />
          <h3>SSB1079 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1080} className="p-2" alt="" />
          <h3>SSB1080 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1081} className="p-2" alt="" />
          <h3>SSB1081 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1082} className="p-2" alt="" />
          <h3>SSB1082 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1083} className="p-2" alt="" />
          <h3>SSB1083 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1084} className="p-2" alt="" />
          <h3>SSB1084 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1085} className="p-2" alt="" />
          <h3>SSB1085 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1086} className="p-2" alt="" />
          <h3>SSB1086 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1087} className="p-2" alt="" />
          <h3>SSB1087 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1088} className="p-2" alt="" />
          <h3>SSB1088 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1089} className="p-2" alt="" />
          <h3>SSB1089 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1090} className="p-2" alt="" />
          <h3>SSB1090 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1091} className="p-2" alt="" />
          <h3>SSB1091 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1092} className="p-2" alt="" />
          <h3>SSB1092 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1093} className="p-2" alt="" />
          <h3>SSB1093 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1094} className="p-2" alt="" />
          <h3>SSB1094 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1095} className="p-2" alt="" />
          <h3>SSB1095 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1096} className="p-2" alt="" />
          <h3>SSB1096 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1097} className="p-2" alt="" />
          <h3>SSB1097 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1098} className="p-2" alt="" />
          <h3>SSB1098 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1098} className="p-2" alt="" />
          <h3>SSB1098 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1099} className="p-2" alt="" />
          <h3>SSB1099 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1101} className="p-2" alt="" />
          <h3>SSB1100 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1101} className="p-2" alt="" />
          <h3>SSB1102 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1102} className="p-2" alt="" />
          <h3>SSB1102 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1103} className="p-2" alt="" />
          <h3>SSB1103 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1104} className="p-2" alt="" />
          <h3>SSB1104 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1105} className="p-2" alt="" />
          <h3>SSB1105 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1106} className="p-2" alt="" />
          <h3>SSB1106 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1107} className="p-2" alt="" />
          <h3>SSB1107 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1108} className="p-2" alt="" />
          <h3>SSB1108 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1109} className="p-2" alt="" />
          <h3>SSB1109 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1110} className="p-2" alt="" />
          <h3>SSB1110 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1112} className="p-2" alt="" />

          <h3>SSB1111 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1112} className="p-2" alt="" />
          <h3>SSB1112 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1113} className="p-2" alt="" />
          <h3>SSB1114</h3>
        </div>
        <div className="text-center ">
          <img src={SSB1114} className="p-2" alt="" />
          <h3>SSB1114 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1115} className="p-2" alt="" />
          <h3>SSB1115 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1116} className="p-2" alt="" />
          <h3>SSB1116 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1117} className="p-2" alt="" />
          <h3>SSB1117 </h3>
        </div>
        <div className="text-center ">
          <img src={SSB1118} className="p-2" alt="" />
          <h3>SSB1118 </h3>
        </div>
      </div>
    </div>
  );
};

export default SliderZipper;
