import {
  faChartBar,
  faEnvelope,
  faPhone,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import useTitle from "../../hooks/useTitle";
import axios from "axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const ContactUs = () => {
  useTitle("Contact Us");

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    service: "",
    message: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post("https://api.clippingpaths.in/submitForm", formData);
      toast.success("Form submitted successfully!", {
        position: toast.POSITION.TOP_CENTER,
      });
      // You can redirect or perform other actions after successful form submission
    } catch (error) {
      console.error("Error submitting form:", error);

      toast.warning(
        "An error occurred while submitting the form. Please try again.",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
    }
  };

  return (
    <div className="pt-40 container mx-auto ">
      <h3 className="text-4xl text-sky-400 font-bold text-center">
        Get In Touch
      </h3>
      <div className="grid grid-cols-2">
        <div className="text-center pt-10 ">
          <h3 className="text-2xl font-bold">Head Office </h3>
          <p>
            House 25, Road 16, Nikunja 2, <br></br>
            Khilkhet, Dhaka 1229, Bangladesh.{" "}
          </p>
        </div>
        {/* row */}

        <div className="text-center pt-10  ">
          <h3 className="text-2xl font-bold">Factory </h3>
          <p>
            259, KabiJashim Uddin Road, <br></br>
            Pagar, Tongi, Gazipur, Bangladesh
          </p>
        </div>
      </div>

      <div className="text-2xl pt-5 text-center">
        <Link to="" className="link link-hover">
          +88 01712958895{" "}
        </Link>
        <br></br>
        <Link to="" className="link link-hover">
          +88 01611382488{" "}
        </Link>
        <br></br>
        <Link to="" className="link link-hover">
          info.ssb@fulpukuria.com
        </Link>
      </div>
      {/* Use Google Font */}

      <div className="lg:pt-20 pt-10 pb-20 grid grid-cols-1">
        <h2 className="text-4xl lg:pb-20 pb-10 font-bold text-sky-600 text-center">
          Keep in Touch
        </h2>
        <div
          className="card lg:card-side grid lg:grid-cols-2 grid-cols-1 gap-8 "
          data-aos="flip-right"
        >
          {/* image */}
          <div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3647.762948268729!2d90.43166167513549!3d23.898023183093287!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c541fed5b46b%3A0xf580518caeede137!2sFulpukuria%20Thread%20And%20Accessories%20Limited.!5e0!3m2!1sen!2sbd!4v1732527210935!5m2!1sen!2sbd"
              width="100%"
              height="450"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
              title="Fulpukuria Thread And Accessories Limited Location"
            ></iframe>
          </div>

          <div className="card-body lg:ml-20 col-span-1 " data-aos="flip-left">
            <div>
              <div className="form-control ">
                <form onSubmit={handleSubmit}>
                  <label className="input-group ">
                    <span className="text-sky-400">
                      <FontAwesomeIcon icon={faUser} />
                    </span>
                    <input
                      type="text"
                      name="name"
                      placeholder="Type Your Name"
                      className="input input-bordered w-full"
                    />
                  </label>

                  <label className="input-group pt-3 ">
                    <span className="text-sky-400">
                      {" "}
                      <FontAwesomeIcon icon={faEnvelope} />
                    </span>
                    <input
                      type="email"
                      name="email"
                      placeholder="Type Your Email"
                      className="input input-bordered w-full"
                    />
                  </label>
                  <label className="input-group pt-3">
                    <span className="text-sky-400">
                      <FontAwesomeIcon icon={faPhone} />
                    </span>
                    <input
                      type="phone"
                      name="phone"
                      placeholder="Type Your Phone Number"
                      className="input input-bordered w-full"
                    />
                  </label>

                  <label className="input-group pt-3 ">
                    <span className="text-sky-400">
                      <FontAwesomeIcon icon={faChartBar} />
                    </span>

                    <textarea
                      className="w-full "
                      placeholder="Type Your Message"
                      name="message"
                      onChange={handleChange}
                    ></textarea>
                  </label>

                  <button
                    className="btn rounded mt-5 w-40 bg-sky-600  border-sky-600 hover:border-2 hover:bg-white  hover:text-sky-600  hover:border-sky-600 shadow-lg"
                    type="submit"
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
