import React from "react";
import "./plasticZipper.css";
import plasticone from "../../assets/images/updated image/2 Plastic Zipper.jpg";

import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import metallarge from "../../assets/images/plastic zipper/Plastic Zipper Forms & Variations.png";
import useTitle from "../../hooks/useTitle";
import plastic3closeend from "../../assets/images/plastic zipper/1.3 Plastic Close End big pic.jpg";
import plastic3openend from "../../assets/images/plastic zipper/2.3  Plastic Open End big pic.jpg";
import plastic5closeend from "../../assets/images/plastic zipper/3.5 Plastic Close End big pic.jpg";
import plastic5openend from "../../assets/images/plastic zipper/4.5 Plastic Open End big pic.jpg";
const PlasticZipper = () => {
  useTitle("Plastic Zipper");
  return (
    <div className="pt-20 container mx-auto ">
      {/* image */}
      <div className="flex justify-center items-center pt-10 mx-5">
        <img src={plasticone} className="pla rounded-xl" alt="" />
      </div>

      <h3 className="text-4xl uppercase font-bold pt-10 text-center">
        Plastic Zippers
      </h3>

      <p className="text-1xl pt-10  text-slate-400">
        Plastic zippers with molded teeth are crafted by injecting each tooth
        individually onto the zipper tape, resulting in a seamless fusion.
        Utilizing high-performance resins, particularly Derlin (also known as
        Polyacetal), these molded plastic components exhibit exceptional
        strength, rendering the zippers highly durable, robust, and endowed with
        a certain degree of flexibility.
      </p>

      <h3 className="text-3xl  text-left pt-10  font-bold">Features</h3>
      <p className="pt-5 pb-2  text-slate-400">
        <span className="text-sky-400 px-4">
          <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
        </span>
        Imported fadeless straight tape
      </p>
      <p className="pt-2 pb-2 text-slate-400">
        <span className="text-sky-400 px-4">
          <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
        </span>
        Available visible chain
      </p>
      <p className="pt-2 pb-2 text-slate-400">
        <span className="text-sky-400 px-4">
          <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
        </span>
        Available visible chain, Enamel Painted & Plating color Slider
      </p>
      <p className="pt-2 pb-2 text-slate-400">
        <span className="text-sky-400 px-4">
          <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
        </span>
        Toughness, Handiness, Environmental friendliness and non-magnetic.
      </p>
      <p className="pt-2 pb-2 text-slate-400">
        <span className="text-sky-400 px-4">
          <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
        </span>
        Durable, dead soft, good ductility, High tensile strength, bright
        surface.
      </p>
      <p className="pt-2 pb-2 text-slate-400">
        <span className="text-sky-400 px-4">
          <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
        </span>
        AZO, Nickel, Lead, Phthalate, Magnet, Ferrous Free
      </p>

      {/* texxt */}
      <p className="text-1xl text-center pt-10">
        Technical characteristics of the Plastic Zippers <br></br> Lightweight,
        plastic-injected zipper that links elegance and functionality. Available
        in a wide range of colours.
      </p>

      <p className="text-center pt-5">
        <span className="font-bold">Technology: </span>
        <br></br> Lightweight, plastic-injected zipper.<br></br> Available
        sizes: <br></br> 3 (3mm) | 5 (5mm) <br></br> Use for: <br></br>Jackets |
        Sweater | Sportswear | |Working clothes | Bags
      </p>

      {/* start */}
      <div className="grid grid-cols-1">
        <div className="flex mx-auto">
          <div className="text-center shadow-lg shadow-black mx-5 mt-5">
            <img src={plastic3closeend} className="w-60" alt="" />
            <h3>Plastic #3 Close End </h3>
          </div>

          <div className="text-center shadow-lg shadow-black mx-5 mt-5">
            <img src={plastic3openend} className="w-60" alt="" />
            <h3>Plastic # 3 Open End</h3>
          </div>
        </div>

        {/* two */}
        <div className="flex mx-auto">
          <div className="text-center shadow-lg shadow-black mx-5 mt-5">
            <img src={plastic5closeend} className="w-60" alt="" />
            <h3>Plastic #5 Close End </h3>
          </div>

          <div className="text-center shadow-lg shadow-black mx-5 mt-5">
            <img src={plastic5openend} className="w-60" alt="" />
            <h3>Plastic # 5 Open End</h3>
          </div>
        </div>
      </div>
      {/* start end */}
      <div className="pt-20">
        <h3 className="text-4xl text-center ">Forms & Variations</h3>

        <div className="">
          <img src={metallarge} className="w-1/2 mx-auto " alt="" />
        </div>
      </div>
    </div>
  );
};

export default PlasticZipper;
