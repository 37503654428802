import React from "react";
import history from "../../assets/images/SSB-History-New.jpg";
const SSB = () => {
  return (
    <div className="pt-20 container mx-auto">
      <div>
        <h2 className="text-3xl font-bold  text-center">
          <span className="text-sky-400 ">SSB </span> History
          <img src={history} className="mt-20 w-1/2  mx-auto " alt="" />
        </h2>
      </div>
    </div>
  );
};

export default SSB;
