import React from "react";
import one from "../../assets/images/chose/Why-Choose-Us.png";
import two from "../../assets/images/chose/two.png";
import three from "../../assets/images/chose/three.png";
import large from "../../assets/images/chose/large.png";
import five from "../../assets/images/chose/four.png";
import six from "../../assets/images/chose/six.png";
import seven from "../../assets/images/chose/largsdse.png";
import eight from "../../assets/images/chose/nine.png";

import "./Chose.css";
const Chose = () => {
  return (
    <div className="container mx-auto">
      <h2 className="text-4xl font-bold  pb-5 mt-10 ">WHY CHOOSE US </h2>
      {/* <h3 className="text-2xl font-bold   pb-5 ">
        <span className="text-blue-600">S</span>USTAINABLE{" "}
        <span className="text-blue-600">S</span>ERVICE{" "}
        <span className="text-blue-600">B</span>ENEFIT
      </h3> */}
      <div className="grid lg:grid-cols-4 col-span-2 mt-10">
        <div>
          <img className="w-32 mx-auto" src={one} alt="" />
          <h4 className="text-2xl font-bold   pb-2 text-center">
            Easy Contact
          </h4>
        </div>

        <div>
          <img className="w-40 mx-auto" src={two} alt="" />
          <h4 className="text-2xl font-bold   pb-2 text-center">
            True Commitment{" "}
          </h4>
        </div>

        <div>
          <img className="w-36 mx-auto" src={three} alt="" />
          <h4 className="text-2xl font-bold   pb-2 text-center">
            Modern Machinery
          </h4>
        </div>

        <div>
          <img className="w-36 mx-auto" src={large} alt="" />
          <h4 className="text-2xl font-bold   pb-2 text-center">
            Large Capacity
          </h4>
        </div>

        <div>
          <img className="w-36 mx-auto" src={five} alt="" />
          <h4 className="text-2xl font-bold   pb-2 text-center">
            Export Quality
          </h4>
        </div>

        <div>
          <img className="w-40 mx-auto" src={six} alt="" />
          <h4 className="text-2xl font-bold   pb-2 text-center">
            ECO Friendly
          </h4>
        </div>

        <div>
          <img className="w-40 mx-auto" src={seven} alt="" />
          <h4 className="text-2xl font-bold   pb-2 text-center">
            Fast Delivery
          </h4>
        </div>

        <div>
          <img className="w-40 mx-auto" src={eight} alt="" />
          <h4 className="text-2xl font-bold   pb-2 text-center">
            Customer Satisfaction
          </h4>
        </div>
      </div>
    </div>
  );
};

export default Chose;
