import React from "react";
import "./ProductCat.css";
import nylon from "../../../assets/images/3.Product-Categories-Picture/1. Nylon Zipper.jpg";
import Plastic from "../../../assets/images/3.Product-Categories-Picture/2. Plastic Zipper.jpg";
import Metal from "../../../assets/images/3.Product-Categories-Picture/3. Metl Zipper.jpg";
import slider from "../../../assets/images/3.Product-Categories-Picture/4. Slider & Puller.jpg";
import sewing from "../../../assets/images/3.Product-Categories-Picture/5. Sewing Thread.jpg";
import { Link } from "react-router-dom";

const ProductCat = () => {
  return (
    <div className="container mx-auto pt-10">
      <h2 className="text-4xl font-bold uppercase pb-10 text-center">
        Products Categories
      </h2>
      <div className="grid grid-cols-3 lg:grid-cols-5">
        <div className="mx-2">
          <Link to="/nylonzipper" className="link link-hover">
            <img className="w-full rounded-sm" src={nylon} alt="" />

            <h3 className="text-2xl font-bold uppercase">Nylon Zipper</h3>
          </Link>
        </div>

        <div className="mx-2">
          <Link to="/plasticzipper" className="link link-hover">
            <img className="w-full rounded-sm" src={Plastic} alt="" />

            <h3 className="text-2xl font-bold uppercase">Plastic Zipper</h3>
          </Link>
        </div>

        <div className="mx-2">
          <Link to="/metalzipper" className="link link-hover">
            <img className="w-full rounded-sm metal" src={Metal} alt="" />

            <h3 className="text-2xl font-bold uppercase">Metal Zipper</h3>
          </Link>
        </div>

        <div className="mx-2">
          <Link to="/sliderzipper" className="link link-hover">
            <img className="w-full rounded-sm slid" src={slider} alt="" />

            <h3 className="text-2xl font-bold uppercase">
              Slider & Puller Zipper
            </h3>
          </Link>
        </div>

        <div className="mx-2">
          <Link to="/thread" className="link link-hover">
            <img className="w-full rounded-sm sew" src={sewing} alt="" />

            <h3 className="text-2xl font-bold uppercase">Sewing Thread</h3>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProductCat;
