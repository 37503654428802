import React from "react";

import Banner from "./Banner/Banner";
import Category from "./Category/Category";
import useTitle from "../../hooks/useTitle";
import ProductCat from "./ProductCat/ProductCat";

import Certification from "../Certification/Certification";
import Chose from "../Chose/Chose";
import Slider from "./Banner/Slider/Slider";

// import WatchNow from "./WatchNow/WatchNow";
// import LatestBlog from "./LatestBlog/LatestBlog";
// import Subscribe from "./Subscribe/Subscribe";
// import ServiceCard from "./ServiceCard/ServiceCard";
const Home = () => {
  useTitle("Home");
  return (
    <div
      className="lg:pt-40 pt-10 text-center  "
      // style={{ background: `url(${Background})` }}
    >
      <Banner></Banner>
      <Category></Category>
      <ProductCat></ProductCat>
      <h3 className="pt-10 text-4xl font-bold pb-10 uppercase">
        Brands We Work With
      </h3>
      <Slider></Slider>
      <Certification></Certification>
      <Chose></Chose>
      {/* <ServiceCard></ServiceCard> */}

      {/* <WatchNow></WatchNow> */}
      {/* <LatestBlog></LatestBlog>
      <Subscribe></Subscribe> */}
    </div>
  );
};

export default Home;
